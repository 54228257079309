import { Navigate } from 'react-router-dom';
import api from '../api';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import axios from 'axios';
import config from '../config/config';
import CryptoJS from 'crypto-js';

export const getAccessToken = async () => {
  const body = {
    grant_type: config.user.grant_type,
    client_id: config.user.client_id,
    client_secret: config.user.client_secret,
    username: config.user.portaluser, //"portaluser"
    password: config.user.portaluserpasw, // "portaluser",
  };
  return await axios.post(`${config.API_BASE_URL}/Api/access_token`, {
    ...body,
  });
};
export const login = async (username, password) => {
  try {
    if (!username || !password) return { data: null };
    const response = await getAccessToken();
    if (response.status !== 200) return { data: null };
    if (response?.data?.access_token) {
      const { access_token, token_type, expires_in, refresh_token } =
        response.data;

      localStorage.setItem('token', access_token);
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('token_type', token_type);
      localStorage.setItem('expires_in', expires_in);
      const md5Password = CryptoJS.MD5(password).toString();
      const params = {
        'fields[Contact]':
          'first_name,treatment_facility,approval_status,language',
        'filter[operator]': 'and',
        'filter[portal_username][eq]': username,
        'filter[operator]': 'and',
        'filter[portal_password][eq]': md5Password, // must replase with md5Password
      };
      const authUser = await api.get(`/Api/V8/module/Contacts`, {
        params: params,
      });

      if (authUser.data?.data[0]) {
        const user = authUser?.data?.data[0];
        // if(user?.attributes?.approval_status  != "Approved"){
        //   throw  new Error('This Client is no longer active please contact administrator for further details');
        // }
        localStorage.setItem('user_id', user.id);
        localStorage.setItem('user_name', user?.attributes?.first_name || '');
        localStorage.setItem('auth_username', username);
        localStorage.setItem('auth_password', md5Password);
        localStorage.setItem(
          'treatment_facility',
          user?.attributes?.treatment_facility || ''
        );
        localStorage.setItem('user_lang', user?.attributes?.language || '');
        config.user.contact_id = user.id;
        return { data: authUser?.data?.data[0] };
      } else {
        return { data: null };
      }
    } else {
      return { data: null };
    }
  } catch (error) {
    let er = error;
    if (error?.response?.data) {
      er = { status: 400, message: error?.response?.data?.message };
    }
    throw er;
  }
};

export const register = async (userData) => {
  try {
    // await api.post('/auth/register', userData);
    return userData;
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  Navigate('/logout');
};

export const getCurrentUser = () => {
  try {
    const token = localStorage.getItem('token');
    if (token) return jwt_decode(token);
    else return false;
  } catch (e) {
    console.log(e);
    return null;
  }
  return null;
};

export const getUserData = async () => {
  try {
    // const user_id = config.user.contact_id
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const response = await api.get(`/Api/V8/module/Contacts/${user_id}`);
    return response?.data || null;
  } catch (error) {
    const err = error?.response?.data || error;
    return err;
  }
};

export const updateUserPassword = async (data) => {
  try {
    if (!data) throw { error: 'Invalid Data' };
    if (!data?.userpassword) throw { error: 'Password field is required' };
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const token_type = localStorage.getItem('token_type') || 'Bearer';
    if (!user_id) throw { error: 'User not found..' };
    const md5Password = CryptoJS.MD5(data?.userpassword).toString();
    const body = {
      id: user_id,
      access_token: token,
      token_type: token_type,
      username: data?.username || '',
      userpassword: md5Password,
    };

    const response = await axios.post(
      `${config.API_BASE_URL_NODE}/api/change/password`,
      body
    );
    return response;
  } catch (err) {
    // alert(err.message);
    return err.message ? err.message : err;
  }
};

export const forgotUserPassword = async (data) => {
  try {
    if (!data) throw { error: 'Invalid Data' };
    if (!data?.email) throw { error: 'Email field is required' };
    const emailStatus = await axios.get(
      `${config.API_BASE_URL_NODE}/api/forgot/password/${data.email}`
    );
    return emailStatus;
  } catch (err) {
    // alert(err.message);
    return err.message ? err.message : err;
  }
};

export const resetPassword = async (data) => {
  try {
    if (!data) throw { error: 'Invalid Data' };
    if (!data?.userpassword) throw { error: 'Password field is required' };
    const user_id = data.userId;
    if (!user_id) throw { error: 'User not found..' };
    const md5Password = CryptoJS.MD5(data?.userpassword).toString();

    const accessToken = await getAccessToken();
    if (!accessToken.data.access_token) {
      throw { error: 'Invalid access token. Please try again later' };
    }
    const body = {
      id: user_id,
      access_token: accessToken?.data?.access_token || '',
      token_type: accessToken?.data?.token_type || 'Bearer',
      userpassword: md5Password,
    };

    const response = await axios.post(
      `${config.API_BASE_URL_NODE}/api/change/password`,
      body
    );
    return response;
  } catch (err) {
    // alert(err.message);
    return err.message ? err.message : err;
  }
};
