import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';
import { getUserData } from '../controllers/auth';
import { lang } from '../config/lang';

const UserProfile = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneOffice, setPhoneOffice] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');

  const parsedUserData = null;

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async (id) => {
      try {
        const user = await getUserData(id);
        const attrs = user?.data?.attributes;
        if (!user || !attrs) {
          alert('Session Expired! Please login again. ');
          navigate('/logout');
          return false;
        }
        setUser(user?.data?.attributes);
        return user?.data;
      } catch (err) {
        console.log(err);
        setError(err?.message || 'Failed to fetch user data');
      }
    };

    fetchUser('afc60744-e183-d04d-a7f0-66dfe12c423d');
  }, []);

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleNewConfirmPasswordChange = (event) => {
    setNewConfirmPassword(event.target.value);
  };

  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    try {
      if (!newPassword || !newConfirmPassword || !oldPassword) {
        return alert('Please fill all required feilds');
      }

      if (newPassword != newConfirmPassword) return alert('Password not match');
      return alert('API not set...');

      axios
        .patch(
          `${config.API_BASE_URL}/api/user/password/update/${parsedUserData._id}`,
          { oldPassword, newPassword }
        )
        .then((res) => {
          console.log(res.data);
          // alert(res.data.msg);
          if (res.data.status == true) {
            navigate('/logout');
          }
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error);
        });
    } catch (error) {
      // alert(error.message);
      console.error('ERROR:', error.message);
    }
  };

  const handleProfileUpdate = (event) => {
    event.preventDefault();

    // console.log(event.target.firstName.value);
    const UserProfile = {
      first_name: event.target.firstName.value,
      last_name: event.target.lastName.value,
      phone: event.target.mobile.value,
      address: event.target.address.value,
      phone_office: event.target.phoneOffice.value,
    };

    try {
      return alert('API not set...');
      axios
        .patch(
          `${config.API_BASE_URL}/api/user/profile/update/${parsedUserData._id}`,
          { ...UserProfile }
        )
        .then((res) => {
          // alert(res.data.msg);
          if (res.data.status == true && res.data.user) {
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(res.data.user));
          }
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error);
        });
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  };

  return (
    <div className="main-box">
      <div className="user-pannel">
        <div className="user-pannel-info mt-5">
          <form
            onSubmit={handleProfileUpdate}
            id="user-profile"
            className="other-data-box"
          >
            <div className=" font-medium justify-between rounded-t-3xl  md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
              {' '}
              <h1 className="text-left h-auto w-full   text-xl">
                {lang('lblProfileHead')}
                {/* Profile Setting */}
              </h1>
            </div>
            <div className="add-document-heading"></div>
            <div className="other-data">
              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row ">
                {' '}
                <h4 className="font-bold w-36 justify-center align-middle pt-1">
                  {lang('lblName')}
                </h4>
                <div className=" text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  flex text-left py-1">
                  {user?.first_name || ''} {user?.last_name || ''}
                </div>
              </div>

              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
              </div>
              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
                <h4 className="font-bold w-36 justify-center align-middle pt-1 ">
                  {lang('lblEmail')}{' '}
                </h4>
                <div className="   text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  py-1 flex text-left ">
                  {user?.email || user?.email1 || ''}
                </div>
              </div>

              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
                <h4 className="font-bold  w-40 justify-center align-middle pt-1">
                  {lang('lblPhnNumb')}{' '}
                </h4>
                <div className="  text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  py-1 flex text-left">
                  {user?.phone_work || ''}
                </div>
              </div>
              {/* <div className="other-data-inner flex justify-around align-middle">
              <h4 className="font-bold w-full justify-center align-middle pt-1">
                Mobile:
              </h4>
              <div className="ml-2 text-sm text-inherit font-light bg-slate-100 w-full pl-3 py-1 flex text-left">
                {user?.phone_mobile || ''}
              </div>
            </div> */}
              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
                <h4 className="font-bold  w-36 justify-center align-middle pt-1">
                  {lang('lblAddress')}{' '}
                </h4>
                <div className="  text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  py-1 flex text-left">
                  {user?.primary_address_street || ''}
                </div>
              </div>
              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
                <h4 className="font-bold  w-40 justify-center align-middle pt-1">
                  {lang('lblPostCode')}{' '}
                </h4>
                <div className="  text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  py-1 flex text-left">
                  {user?.primary_address_postalcode || ''}
                </div>
              </div>
              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
                <h4 className="font-bold  w-36 justify-center align-middle pt-1">
                  {lang('lblCity')}{' '}
                </h4>
                <div className=" text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  py-1 flex text-left">
                  {user?.primary_address_city || ''}
                </div>
              </div>
              <div className="other-data-inner flex justify-around align-middle xs:flex-col sm:flex-row">
                <h4 className="font-bold  w-40 justify-center align-middle pt-1">
                  {lang('lblCountry')}{' '}
                </h4>
                <div className=" text-sm text-inherit font-light bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 w-full  py-1 flex text-left">
                  {user?.primary_address_country || ''}
                </div>
              </div>
             
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
