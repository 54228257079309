import api from '../api';
import { format } from 'date-fns';

export const getMeetingList = async () => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const response = await api.get(
      `/Api/V8/module/Contacts/${user_id}/relationships/meetings`
    );

    return response;
  } catch (error) {
    const err = error?.response?.data || error;
    throw err;
  }
};

export const getMeetingToday = async () => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const dateNow = format(new Date(), 'yyyy-MM-dd');

    const params = {
      'filter[operator]': 'and',
      'filter[date_start][gte]': `${dateNow}T00:00:00and`,
      'filter[date_end][lt]': `${dateNow}T23:59:59`,
    };
    const response = await api.get(
      `/Api/V8/module/Contacts/${user_id}/relationships/meetings`,
      { params }
    );
    return response;
  } catch (error) {
    const err = error?.response?.data || error;
    throw err;
  }
};

export const createMeetings = async (data) => {
  try {
    if (!data) return { data: { message: 'data not found...' } };
    const meetings = {
      data: {
        type: 'Meetings',
        attributes: {
          subject: data.subject,

          date: data.date,
          status: data.status,
        },
      },
    };

    const response = await api.post(`/Api/V8/module`, meetings);
    if (response.status === 201) {
      const linkDocsClient = {
        data: {
          type: 'Meetings',
          id: response.data.data.id,
        },
      };
      const user_id = localStorage.getItem('user_id');
      if (!user_id)
        return {
          data: { message: 'Document update failed. please try again later' },
        };
      const response2 = await api.post(
        `/Api/V8/module/Contacts/${user_id}/relationships/documents`,
        linkDocsClient
      );
      return response2;
    } else {
      return { data: { message: 'data not found...' } };
    }
  } catch (err) {
    throw err;
  }
};
