import api from '../api';
import axios from 'axios';
import config from '../config/config';
export const getUsernotification = async () => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const url = `/Api/V8/module/Alerts?filter[operator]=and&filter[target_module][eq]=Contacts&filter[operator]=and&page[size]=200&sort=-date_entered&filter[operator]=and&filter[url_redirect][eq]=${user_id}&filter[operator]=and&filter[record_module][neq]=NULL`;
    const response = await api.get(url);
    // const response = await api.get(`/Api/V8/module/Contacts/${user_id}/relationships/documents`);
    return response;
  } catch (error) {
    const err = error?.response?.data ? error?.response?.data : error;
    throw err;
  }
};
export const notificationIsRead = async (data) => {
  try {
    if (!data) return { data: null };
    const token = localStorage.getItem('token');
    if (!token) throw { error: 'Session Expired: please login again' };
    const token_type = localStorage.getItem('token_type') || 'Bearer';
    const body = {
      user: {
        access_token: token,
        token_type: token_type,
      },
      data: {
        data: {
          type: 'Alerts',
          id: data.id,
          attributes: {
            is_read: '1',
          },
        },
      },
    };
    const response = await axios.post(
      `${config.API_BASE_URL_NODE}/api/update/notification`,
      body
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// export const linkNotificationWithCase = async (data) => {
//   try {
//     const notificationToCase = {
//       data: {
//         type: 'Alert', // Replace with the correct type if different
//         id: data.notificationId, // Notification ID to link
//       },
//     };

//     // Make API call to link the notification to the case
//     const response = await api.post(
//       `/Api/V8/module/Cases/${data.caseId}/relationships/notifications`, // Endpoint to link the notification to the case
//       notificationToCase
//     );

//     return response;
//   } catch (err) {
//     return err;
//   }
// };
// export const getCaseAllThreads = async (caseId) => {
//   try {
//     const response = await api.get(
//       `${config.API_BASE_URL}/Api/V8/module/Cases/${caseId}/relationships/aop_case_updates?filter[operator]=and&filter[internal][neq]=1`
//     );
//     const notificationsResponse = await api.get(
//       `${config.API_BASE_URL}/Api/V8/module/Cases/${caseId}/relationships/notifications`
//     );

//     // Combine threads and notifications
//     return {
//       threads: response.data,
//       notifications: notificationsResponse.data,
//     };
//   } catch (er) {
//     return er;
//   }
// };
// export const createNotificationAndLinkToCase = async (data) => {
//   try {
//     // Assuming data contains necessary information for creating the notification
//     const notificationResponse = await getUsernotification(data); // You'll need to implement createNotification
//     const notificationId = notificationResponse.data.id;

//     // Now link the notification to a case
//     const linkResponse = await linkNotificationWithCase({
//       caseId: data.caseId,
//       notificationId: notificationId,
//     });

//     return linkResponse;
//   } catch (error) {
//     return error;
//   }
// };
