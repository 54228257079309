import Cookies from 'js-cookie';
import { login } from './auth';

export const setCookie  = (key, value, expireIn = 1 ) => {
    if(!key  || !value) return false;
    localStorage.setItem(key, value);
    // Cookies.set(key, value, { path: '/logout' });
    return true;
}

export const getCookie  = (key) => {
    return localStorage.getItem(key);
    // return Cookies.get(key);
}

export const removeCookie = (key = '') => {
    Cookies.remove(key);
    return true;
}

export const rememberMeLogin =  () => {
    const username  = getCookie('username');
    const password  = getCookie('password');
       
    if(username  && password) {
        try{
             if(localStorage.getItem('user_id')){
                return true;
             }
             const response =  login(username, password).then( res => {
                return res;
             }).catch( err => {
                return false
             } );
             return response;
        }catch(er){
            return false;
        }
    }else{
        return false;
    } 
}
