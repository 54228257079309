import React, { useState } from "react";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef } from "react";
import { updateDocument } from "../controllers/documents";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import config from "../config/config";
import axios from "axios";
import { lang } from "../config/lang";
import { getDocumentTypeList } from "../controllers/documents";
import { getTypeLabelByKey } from "../helpers/general-helper";
import { getDocuemtnTypeLabelByKey } from "../helpers/general-helper";
const EditDocument = () => {
  const [alert, setAlert] = useState({ message: "", severity: "" });
  const [loading, setLoading] = React.useState(false);
  const [fileError, setFileError] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const [fileSelected, setFileSelected] = useState("");
  const [base64String, setBase64String] = useState("");
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [docTypeOpLoading, setDocTypeOpLoading] = useState(false);
  // const [docName, setBase64String] = useState('');

  const doc = location.state;
  const [type, setType] = useState(doc.type);

  // const [datePublish, setDatePublish] = useState(
  //   new Date(doc?.dateCreated || '').toLocaleDateString('en-CA')
  // );
  // const [dateExpire, setDateExpire] = useState(
  //   new Date(doc?.dateExpired || '').toLocaleDateString('en-CA')
  // );
  const [docName, setDocName] = useState(doc?.documentName || "");
  const [removeDocName, setRemoveDocName] = useState(doc?.documentName);

  const handleDocumentTypeList = async () => {
    try {
      const resp = await getDocuemtnTypeLabelByKey();
      setDocTypeOptions(resp);
    } catch (er) {
      console.log(er);
    } finally {
      setDocTypeOpLoading(true);
    }
  };
  React.useEffect(() => {
    if (!docTypeOpLoading) {
      handleDocumentTypeList();
    }
  }, [docTypeOpLoading]);

  // Set selected option based on the type value
  const selectedOption = docTypeOptions.find(
    (option) => option.label === doc.type
  );

  const typeChange = (selectedOption) => {
    setType(selectedOption.value); // Store the value directly
  };
  // const inputRef = useRef(null);
  // const expirationDateRef = useRef(null);
  // const handleInputClick = () => {
  //   if (inputRef.current) {
  //     inputRef.current.showPicker();
  //   }
  // };
  // const handleExpirationDateClick = () => {
  //   if (expirationDateRef.current) {
  //     expirationDateRef.current.showPicker();
  //   }
  // };

  const handleKeyToLabel = (key) => {
    const data = docTypeOptions;
    let lblType = "";
    for (let i in data) {
      if (data[i].value == key) {
        lblType = data[i].label;
        break;
      }
    }
    return lblType;
  };

  const haldleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setAlert({
        message: "Document updating please wait...",
        severity: "info",
      });
      const data = {
        id: doc?.id || "",
        docName: event.target.docName.value || "",
        filename: doc.fileName || "",
        // expireDate: event.target.expirationDate.value || '',
        description: event.target.description.value || "",
        doc_type: type,
        file: fileSelected || "",
        fileBase64: base64String || "",
      };
      // if (!fileSelected) {
      //   return setAlert({
      //     message: 'Please select new document',
      //     severity: 'error',
      //   });
      // }
      // console.log(data); return;
      const response = await updateDocument(data);
      if (response?.status === 200) {
        setAlert({
          message: "Document updated successfully",
          severity: "success",
        });
        navigate(-1);
      }
    } catch (err) {
      console.log("server:err", err.message);

      setAlert({ message: "Document update failed", severity: "error" });
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setRemoveDocName(selectedFile.name);
    if (selectedFile) {
      const maxSize = 15 * 1024 * 1024;
      if (selectedFile.size > maxSize) {
        setError("File size should not exceed 15MB.");
        setFileSelected(false);
        event.target.value = "";
      } else {
        setError("");
        setFileSelected(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setBase64String(base64String.split(",")[1]);
        };
        reader.readAsDataURL(selectedFile);
      }
    } else {
      setFileSelected(false);
    }
  };

  const handleLoadDocLinks = () => {
    try {
      //  const resDocsLink = axios.get(`http://localhost/connectcrm/index.php?preview=yes&entryPoint=viewDocument&id=${doc?.document_revision_id}&type=Documents`);
    } catch (er) {
      console.log(er);
    }
  };
  handleLoadDocLinks();
  const downloadDocLink = `${config.API_BASE_URL}/index.php?preview=yes&entryPoint=viewDocument&id=${doc?.document_revision_id}&type=Documents`;
  return (
    <form method="post" onSubmit={haldleFormSubmit}>
      <div className="add-document-main-div">
        <div className="add-document-inner-div">
          <div className="flex flex-col  justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            {alert.message && (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
              </Stack>
            )}

            <h2 className="text-left h-auto   text-xl font-medium">
              {lang("lblEditDoc")}
            </h2>
          </div>
          <div className="add-document-heading"></div>
          <div className="add-document-inputs">
            <div className="document-name">
              <label className="add-doc-label">
                {lang("lblDocumentName")}{" "}
                <span className="add-doc-span">*</span>
              </label>
              <input
                type="text"
                name="docName"
                className="add-doc-name-input"
                value={docName || ""}
                onChange={(e) => setDocName(e.target.value)}
              />
            </div>
            {removeDocName === doc?.documentName ? (
              <div className="document-name">
                <label className="add-doc-label">
                  {" "}
                  {lang("lblFileName")} <span className="add-doc-span">*</span>
                </label>
                <div className="flex justify-start align-middle gap-2 text-blue-600 text-sm">
                  <div className=" svg-icon-add-case">
                    <svg
                      width="22"
                      height="18"
                      viewBox="0 0 22 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#8D8D8D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="hover:stroke-blue-600 stroke-1"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.4177 8.89427C14.4177 10.7818 12.8869 12.3116 10.9993 12.3116C9.11176 12.3116 7.58203 10.7818 7.58203 8.89427C7.58203 7.00562 9.11176 5.47589 10.9993 5.47589C12.8869 5.47589 14.4177 7.00562 14.4177 8.89427Z"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.9978 16.7881C15.1146 16.7881 18.88 13.8281 21 8.89405C18.88 3.96 15.1146 1 10.9978 1H11.0022C6.8854 1 3.12 3.96 1 8.89405C3.12 13.8281 6.8854 16.7881 11.0022 16.7881H10.9978Z"
                      ></path>
                    </svg>
                    <a
                      href={downloadDocLink}
                      download={doc.fileName}
                      target="_blank"
                    >
                      {lang("lblViewDoc")}
                    </a>
                  </div>

                  <div className="svg-icon-add-case">
                    <a
                      href=""
                      className=""
                      onClick={(e) => {
                        e.preventDefault();
                        setRemoveDocName("");
                      }}
                    >
                      {lang("lblRemoveDoc")}
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="document-name">
                <label className="add-doc-label">
                  {lang("lblFileName")} <span className="add-doc-span">*</span>
                </label>
                <input
                  type="file"
                  className="add-file-input"
                  onChange={handleFileChange}
                  style={{ borderColor: fileError ? "red" : "" }}
                />
                {error && (
                  <p
                    style={{
                      borderColor: "red",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {error}
                  </p>
                )}
                {fileError && (
                  <p style={{ color: "red", textAlign: "left" }}></p>
                )}
                <p className="add-doc-p">{lang("lblMaxFileSize")}</p>
              </div>
            )}

            {/* <div className="document-name" onClick={handleInputClick}>
              <label className="add-doc-label">
                Publish Date <span className="add-doc-span">*</span>
              </label>
              <input
                ref={inputRef}
                type="date"
                name="publishDate"
                className="add-doc-name-input"
                value={datePublish}
                onChange={(e) => setDatePublish(e.target.value)}
              />
            </div>
            <div className="document-name" onClick={handleExpirationDateClick}>
              <label className="add-doc-label">Expiration Date</label>
              <input
                ref={expirationDateRef}
                name="expirationDate"
                type="date"
                className="add-doc-name-input"
                value={dateExpire}
                onChange={(e) => setDateExpire(e.target.value)}
              />
            </div> */}
            <div className="document-name">
              <label htmlFor="type" className="text-left text-sm mb-2 ">
                {lang("lblType")} <span className="add-doc-span">*</span>
              </label>
              <Select
                id="type"
                name="type"
                placeholder={handleKeyToLabel(type)}
                className="add-doc-name-input1"
                options={docTypeOptions}
                onChange={typeChange}
                value={type || selectedOption}
                maxMenuHeight={150}
                isClearable={false}
                isSearchable={false}
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: "black",
                    padding: "0px",
                    textAlign: "left",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "",
                    border: "none",
                    height: "auto",
                    textAlign: "left",
                  }),
                  menu: (provided) => ({
                    ...provided,
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                    textAlign: "left",
                  }),
                }}
              />
            </div>

            <div className="document-name">
              <label className="add-doc-label">{lang("lblNote")}</label>
              <textarea name="description" className="add-doc-textarea">
                {doc?.description || ""}
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="add-doc-btn">
        <div className="add-btn">
          <button className="add-doc-inner-btn1" type="submit">
            {loading ? lang("lblUpdate") : lang("lblBtnSave")}
          </button>
          <button
            className="add-doc-inner-btn2"
            onClick={() => navigate("/user/documents")}
          >
            {lang("lblBtnCancel")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditDocument;
