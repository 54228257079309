import axios from 'axios';
import isTokenExpired  from './utils/isTokenExpired';
import config from './config/config';

const api = axios.create({
  baseURL: config.API_BASE_URL, // Replace with your API base URL
});

api.interceptors.request.use(
  (config) => {
    const token_type = localStorage.getItem('token_type') || 'Bearer';
    const token = localStorage.getItem('token');
    const expires_in = localStorage.getItem('expires_in');
    const access_token = localStorage.getItem('access_token');

    if (!token || isTokenExpired()) {
      return Promise.reject('Token expired or not found.');
    }
    config.headers.Authorization = `${token_type} ${token}`; // Set token in header
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;