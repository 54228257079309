import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import config  from "../config/config";

const Signup = (props) => {
  const { handleGoBack } = props;

  const navigate = useNavigate();
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [birth_date, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [apartment, setApartment] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleFirstNameChange = (event) => {
    setFirstname(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastname(event.target.value);
  };
  const handleBirthDateChange = (event) => {
    setBirthDate(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleApartmentChange = (event) => {
    setApartment(event.target.value);
  };
  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  };
  const handelCityChange = (event) => {
    setCity(event.target.value);
  };
  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function isValidPassword(password) {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (!password) return  alert("Please enter a password");
    if (!username) return  alert("Please enter a username");
    if (!email) return  alert("Please enter a email");
      try {
          await axios.post(`${config.API_BASE_URL}/api/auth/register`, { first_name, last_name, phone, birth_date, email, username, apartment, street, city, state, country, postal_code, password })
                      .then((res) => {
                          if(res.status == 201){
                            // alert(res.data.msg);
                            localStorage.setItem('token', res.data.token);
                            setAuthToken(res.data.token);
                            navigate('/')
                          }else if(res.status == 400){
                            // alert(res.data.msg);
                          }
                        }).catch((error) =>  {
                          throw new Error(error.response.data.msg);
                        });
        
      } catch (error) {
        // alert(error.message);
        console.error("ERROR:", error.message);
      }
    
  };

  return (
    <main id="page">
      <div id="sign-page">
        <div className="image">
          <img
            src="https://portaldemo.crmjetty.com/suite-wp-portal-demo/wp-content/uploads/2024/08/suitecrm-logo.png"
            className="page2-img"
          ></img>
          <h1>Portal Sign Up</h1>
        </div>
        <div className="user-info">
          <div className="responsive">
            <form action="submit" method="post"  onSubmit={handleSignUp}>

              <div className="inner-info">
                <div className="info">
                  <h3>
                    Username <span className="mark">*</span>
                  </h3>

                  <input
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    required
                    placeholder="Please enter user-name"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Password <span className="mark">*</span>
                  </h3>
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    placeholder="Please enter your password"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    First Name <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    placeholder="Please enter your first-name"
                    value={first_name}
                    onChange={handleFirstNameChange}
                    required
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Last Name <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={last_name}
                    onChange={handleLastNameChange}
                    required
                    placeholder="Please enter your last-name"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Email <span className="mark">*</span>
                  </h3>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    placeholder="Please enter your Email"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Phone <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={phone}
                    onChange={handlePhoneChange}
                    required
                    placeholder="Please enter your Phone"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Birth-Date <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={birth_date}
                    onChange={handleBirthDateChange}
                    required
                    placeholder="Please enter your BirthDate"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Appartment <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={apartment}
                    onChange={handleApartmentChange}
                    placeholder="Please enter your Appartment"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Street <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={street}
                    onChange={handleStreetChange}
                    placeholder="Please enter your Street"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    City <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={city}
                    onChange={handelCityChange}
                    placeholder="Please enter your City"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    State <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={state}
                    onChange={handleStateChange}
                    placeholder="Please enter your State"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Postal Code <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={postal_code}
                    onChange={handlePostalCodeChange}
                    placeholder="Please enter your Postal Code"
                  ></input>
                </div>
                <div className="info">
                  <h3>
                    Country <span className="mark">*</span>
                  </h3>
                  <input
                    type="text"
                    value={country}
                    onChange={handleCountryChange}
                    placeholder="Please enter your country"
                  ></input>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="footer-2">
          <div className="btn-2">
            <button
              className="signin-up-btn"
              type="button"
              onClick={handleSignUp}
            >
              Sign Up
            </button>
          </div>
          <div class="registration-footer">
            Already have an account?{" "}
            <a onClick={() => navigate("/")}>Sign In</a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Signup;
