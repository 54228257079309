import api from '../api';
import axios from 'axios';
export const getUsernotification = async () => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const url = `/Api/V8/module/Alerts?filter[operator]=and&filter[target_module][eq]=Contacts&filter[operator]=and&filter[is_read][eq]=0&filter[operator]=and&filter[url_redirect][eq]=${user_id}`;

    const response = await api.get(url);

    // const response = await api.get(`/Api/V8/module/Contacts/${user_id}/relationships/documents`);

    return response;
  } catch (error) {
    const err = error?.response?.data ? error?.response?.data : error;
    throw err;
  }
};
