import React from 'react';
import Card from '../../Card';
import Case from '../../images/counter-cases.svg';
import Invoice from '../../images/invoice-receipts-line-svgrepo-com.svg';
import MeetingCard from '../../components/MeetingCard';
import documents from '../../images/document-text-svgrepo-com.svg';
import meetings from '../../images/meeting_13085436.svg';
import Calls from '../../images/counter-calls.svg';
import config from '../../config/config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { lang } from '../../config/lang';
import PieChartWithCenterLabel from '../../components/pie';
import Typography from '@mui/material/Typography';

const phoneDisplay = (number) => {
  // Check if language is Dutch using your existing function
    if (localStorage.getItem('user_lang') === 'Dutch') {
      // Replace +31 with 0
      return number.replace('+31 20', '020');
    }
    return number;
};
const MemberHomePage = () => {
  const [totalMeetings, setTotalMeetings] = React.useState(0);
  const [totalDocuments, setTotalDocuments] = React.useState(0);
  const [totalInvoices, setTotalInvoices] = React.useState(0);
  const [totalCases, setTotalCases] = React.useState(0);
  const [totalCalls, setTotalCalls] = React.useState(0);
  const [letterClinic, setLetterClinic] = React.useState();
  const [letterAfterCare, setLetterAfterCare] = React.useState();
  const [isLoadindCounts, setIsLoadindCounts] = React.useState(false);
  const firstName = localStorage.getItem('user_name') || '';
  const treatmentFacility = localStorage.getItem('treatment_facility');
  const insurancePrivate = localStorage.getItem('insurance_private');
  const stage = localStorage.getItem('stage');
  const handleDataCounts = async () => {
    const user_id = localStorage.getItem('user_id');
    await axios
      .get(`${config.API_BASE_URL_NODE}/api/dashboard/count/${user_id}`)
      .then((res) => {
        setLetterClinic(res?.data.referral_letter_clinic);
        setLetterAfterCare(res?.data.referral_letter_aftercare);
        setTotalMeetings(res?.data?.totalMeetings);
        setTotalDocuments(res?.data?.totalDocuments);
        setTotalInvoices(res?.data?.totalInvoices);
        setTotalCases(res?.data?.totalCases);
        setTotalCalls(res?.data?.totalCalls);
      })
      .catch((err) => {
        if (err.code) {
          alert(err.code);
        }
      })
      .finally(() => {
        setIsLoadindCounts(true);
      });
  };
  React.useEffect(() => {
    if (!isLoadindCounts) {
      handleDataCounts();
    }
  }, [isLoadindCounts]);

  const location = useLocation();
  const doc = location.state || {};
  const navigate = useNavigate();

  return (
    <div>
      <div className="grid  lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2 gap-4  justify-center mt-7 ">
        {/* */}
        <div
          className="md:col-span-2  lg:col-span-1 cursor-pointer"
          onClick={() => {
            navigate('/documents');
          }}
        >
          <Card
            title={lang('lblDocuments')}
            pic={documents}
            bgcolor={'bg-red-200'}
            contentText={totalDocuments}
          />
        </div>
        <div
          className="md:col-span-2  lg:col-span-1 cursor-pointer"
          onClick={() => {
            navigate('/meetings');
          }}
        >
          <Card
            title={lang('lblMeetings')}
            pic={meetings}
            bgcolor={'bg-yellow-100'}
            contentText={totalMeetings}
          />
        </div>
        <div
          className="md:col-span-2  lg:col-span-1 cursor-pointer"
          onClick={() => {
            navigate('/myquestions');
          }}
        >
          <Card
            title={lang('lblCases')}
            pic={Case}
            bgcolor={''}
            contentText={totalCases}
          />
        </div>
        <div
          className="md:col-span-2 lg:col-span-1 cursor-pointer"
          onClick={() => {
            navigate('/invoices');
          }}
        >
          <Card
            title={lang('lblInvoices')}
            pic={Invoice}
            bgcolor={'bg-sky-200'}
            contentText={totalInvoices}
          />
        </div>
        <div className=" h-full w-full items-center border rounded-2xl   shadow-lg bg-white md:col-span-4 xs:col-span-2 ">
          <div className="text-left md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5 text-xl font-medium">
            <h2 className="text-left h-auto   text-xl">Welcome {firstName}</h2>
          </div>{' '}
          <div className="add-document-heading"></div>
          <Typography 
            sx={{
              textAlign: 'left  ',

              fontFamily: 'inherit',
            }} variant="body1" component="p" className="text-left md:px-8 xs:px-4 md:pt-6 md:pb-6 xs:pt-5 xs:pb-5">
            Your client portal provides easy access to everything you need. Upload and view documents, check your scheduled meetings, and track invoices and their status-all in one place.
            <br />
            <br />
            Need assistance? Our dedicated admission managers are available to help whenever you have questions.
            <br />
            <br />
            Ask them online via the MyQuestions. You can also speak to your admission manager right now. Call us on:{' '} 
            <a
              href={`tel:${treatmentFacility === 'VC' ? '+31208085484' : treatmentFacility === 'CWH' ? '+31208083758' : '+31208080187'}`}
              style={{ textDecoration: 'underline', color: 'black' }}
            >
            {treatmentFacility === 'VC' && phoneDisplay('+31 20 808 5484')}
            {treatmentFacility === 'CWH' && phoneDisplay('+31 20 808 3758')}
            {treatmentFacility === 'AH' && phoneDisplay('+31 20 808 0187')}
            </a>
            <br />
            <br />
            {treatmentFacility === 'VC' && 'Team Villa Consano'}
            {treatmentFacility === 'CWH' && 'Team Cherrywood House'}
            {treatmentFacility === 'AH' && 'Team Anker Huis'}
          </Typography>
        </div>
        {insurancePrivate !== 'Private' && (stage === 'Intake' || stage === 'Aftercare') ? (
          <div className=" h-full w-full items-center border rounded-2xl   shadow-lg bg-white md:col-span-4 xs:col-span-2 ">
            <div className="text-left md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5 text-xl font-medium">
              <h2 className="text-left h-auto   text-xl">{lang('lblQuickLinks')}</h2>
            </div>{' '}
            <div className="add-document-heading"></div>
            <div className="flex px-8 py-4">
            {letterClinic == '0' ? (
                  <a
                    className="dashboard-inner-btn1 h-20 cursor-pointer md:mr-4 xs:mr-2"
                    type="submit"
                    onClick={() =>
                      navigate('add', {
                        state: { type: 'Referral_letter_clinic' },
                      })
                    }
                  >
                    {lang('lblUploadReferralMainB')}
                  </a>
                ) : (
                  <a
                    className="dashboard-inner-btn2 h-20 cursor-pointer md:mr-4 xs:mr-2"
                    type="submit"
                    href={letterClinic}
                  >
                    {lang('lblUploadReferralMainDownloadB')}
                  </a>
                )}
                {letterAfterCare == '0' ? (
                  <a
                    className="dashboard-inner-btn1 h-20 cursor-pointer"
                    onClick={() =>
                      navigate('add', {
                        state: { type: 'Referral_letter_After_care' },
                      })
                    }
                  >
                    {lang('lblUploadReferralMainA')}
                  </a>
                ) : (
                  <a
                    className="dashboard-inner-btn2 h-20 cursor-pointer"
                    href={letterAfterCare}
                  >
                    {lang('lblUploadReferralMainDownloadA')}
                  </a>
                )}
            </div>
          </div>
        ) : ''}
        <div className="md:col-span-4 xs:col-span-2 ">
          <MeetingCard />
        </div>
        {/* <div className=" h-full w-full items-center border rounded-2xl   shadow-lg bg-white md:col-span-4 xs:col-span-2 ">
          <div className="text-left md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5 text-xl font-medium">
            <h2 className="text-left h-auto   text-xl">{lang('lblSummary')}</h2>
          </div>{' '}
          <div className="add-document-heading"></div>
          <PieChartWithCenterLabel />
        </div> */}
      </div>
    </div>
  );
};

export default MemberHomePage;
