import { getDocumentTypeList } from '../controllers/documents';
import { lang } from '../config/lang';
const priotiryOptions = [
  { label: lang('lblPriorityHigh'), value: 'P1' },
  { label: lang('lblPriorityMed'), value: 'P2' },
  { label: lang('lblPriorityLow'), value: 'P3' },
];
const documentType = [
    { label: lang('lblPid'), value: 'PID' },
    { label: lang('lblInvoices'), value: 'Invoice' },
    { label: lang('lblTreatmentPlan'), value: 'Treatment_plan' },
    { label: lang('lblIntake'), value: 'Intake report' },
    { label: lang('lblMate'), value: 'MATE' },
    { label: lang('lblDefense'), value: 'Defense_letter' },
    { label: lang('lblAppForInsurance'), value: 'Application_for_insurance' },
    { label: lang('lblDischarge'), value: 'Discharge_report' },
    { label: lang('lblReferralLetterClinic'), value: 'Referral_letter_clinic' },
    { label: lang('lblReferralLetterAftercare'), value: 'Referral_letter_After_care' },
    { label: lang('lblConfirmationLetterAfterCare'), value: 'Confirmation_letter_After_care' },
    { label: lang('lblTreatmentHistory'), value: 'Treatment_history' },
    { label: lang('lblInsurerDocument'), value: 'Insurer_document' },
    { label: lang('lblDefense'), value: 'Defense_letter' },
    { label: lang('lblProofOfPayment'), value: 'Proof_of_payment' },
    { label: lang('lblAccountabilityDocument'), value: 'Accountability_document' },
    { label: lang('lblMotivationLetter'), value: 'Motivation_letter' },
    { label: lang('lblFlightDetails'), value: 'Flight_details' },
    { label: lang('lblOtherDoc'), value: 'Other' },
  ];
export const getPriorityLabelByKey = (key) => {
  for (let index in priotiryOptions) {
    if (priotiryOptions[index].value == key) {
      return priotiryOptions[index].label;
    }
  }

  return key;
};

export const getTypeLabelByKey = (key) => {
  for (let index in documentType) {
    if (documentType[index].value == key) {
      return documentType[index].label;
    }
  }

  return key;
};

export const getDocuemtsTypeLabelByKey = () => {
  
  return documentType;
};
export const getDocuemtnTypeLabelByKey = () => {
  const documentType = [
    { label: lang('lblReferralLetterClinic'), value: 'Referral_letter_clinic' },
    { label: lang('lblReferralLetterAftercare'), value: 'Referral_letter_After_care' },
    { label: lang('lblConfirmationLetterAfterCare'), value: 'Confirmation_letter_After_care' },
    { label: lang('lblTreatmentHistory'), value: 'Treatment_history' },
    { label: lang('lblInsurerDocument'), value: 'Insurer_document' },
    { label: lang('lblDefense'), value: 'Defense_letter' },
    { label: lang('lblProofOfPayment'), value: 'Proof_of_payment' },
    { label: lang('lblAccountabilityDocument'), value: 'Accountability_document' },
    { label: lang('lblMotivationLetter'), value: 'Motivation_letter' },
    { label: lang('lblFlightDetails'), value: 'Flight_details' },
    { label: lang('lblOtherDoc'), value: 'Other' },
  ];
  return documentType;
};
