import React, { useState, useEffect } from 'react';
import * as reactdom from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const EmailConfirmation = () => {
  const [Confirmation_code, set_Confirmation_code] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  const handleCodeInut = (event) => {
    set_Confirmation_code(event.target.value);
  };
  const confirm_Email_Address = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`/api/client/emailconfirmation`, {
        // Replace with your auth endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          codeForConfirmation: Confirmation_code,
        }),
      });

      if (!response.ok) {
        throw new Error('User Creation failed');
      }

      const data = await response.json();

      console.log('Email COnfirmedd');
      // navigate('/emailConfirmation', { state: userEmail });
      navigate('/');
      // window.alert('Registration Successful')

      // Handle successful authentication (e.g., redirect, store token)
    } catch (error) {
      console.error('Error Creating user:', error.message);
    }
  };
  return (
    <>
      {' '}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="m-auto border border-light rounded-2xl">
            Please Confrim your Email Address
          </div>

          <div className="m-auto border-dark content-center">
            <p className="py-8 text-left">
              Email Confirmation is sent to Your Email Address {data || ''}.
              Please check your inbox, don't forget to check spam folder. We'll
              wait for the confirmation before proceeding.
            </p>
            <label htmlFor="emailConfirmation">
              <div className="text-left w-full">
                Enter your Confirmation Code:
              </div>
            </label>
            <input
              type="text"
              id="emailConfirmation"
              onChange={handleCodeInut}
              value={Confirmation_code}
              className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

            <div className=" content-center ">
              <button
                onClick={confirm_Email_Address}
                className="flex w-[30%] mx-auto my-6 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailConfirmation;
