import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { getUserDocuments } from '../controllers/documents';
import { getFormatedDateTime } from '../helpers/dt-helper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from '../config/config';
import { getAllCases } from '../controllers/cases';
import { lang } from '../config/lang';
import { getCasePriorityOptions, getCasesTypeList } from '../controllers/cases';

const MUITableComponent = () => {
  // State to store the fetched data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [totalCount, setTotalCount] = useState(0); // Total number of records for pagination
  const [allSelected, setAllSelected] = useState(false);
  const [isAnySelected, setIsAnySelected] = useState(false);
  const [priotiryOptions, setPriorityOptions] = useState([]);
  const [download, setDownload] = useState();
  const navigate = useNavigate();
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const handleEditCase = (data) => {
    navigate(`edit/${data.id}`, { state: { ...data } });
  };
  const handlePrevCase = (data) => {
    navigate(`detail/${data.id}`, { state: { ...data } });
  };
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(','); // Get CSV headers
    const rows = data.map((row) => {
      return Object.values(row)
        .map((value) => {
          if (value instanceof Date) {
            return getFormatedDateTime;
          }
          return value;
        })
        .join(',');
    });

    return [headers, ...rows].join('\n'); // Combine headers and rows
  };
  // State for sorting
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('caseNumb'); // Default sort by "id"
  // Fetch data from API when the component mounts
  const fetchData = async (p, rp) => {
    try {
      let rows = [];
      const docs = await getAllCases(p, rp);
      const { 'total-records': totalRecords, 'total-pages': totalPages } =
        docs?.data?.meta;
      rows = docs?.data?.data.map((doc) => {
        const {
          name,
          type,
          priority,
          description,
          date_entered,
          case_number,
          status,
        } = doc.attributes;
        return {
          id: doc.id,
          subject: name,
          type: type,
          priority: priority,
          date: getFormatedDateTime(date_entered),
          description: description,
          caseNumb: case_number,
          Status: status,
        };
      });
      setData(rows);
      setTotalCount(totalRecords ? totalRecords : 0);
      const caseTypeList = getCasesTypeList();
      setDocTypeOptions(caseTypeList);
      const casePriority = await getCasePriorityOptions();
      setPriorityOptions(casePriority);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(true);
    }
  };
  useEffect(() => {
    if (!isLoading) {
      fetchData(page, rowsPerPage);
    }
  }, [page, rowsPerPage, isLoading]);

  const getPriorityLabel = (key) => {
    if (!priotiryOptions) {
      return key;
    }
    for (let index in priotiryOptions) {
      if (priotiryOptions[index].value == key) {
        return priotiryOptions[index].label;
      }
    }
    return key;
  };
  // Handle sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort the data based on the order and orderBy state
  const sortedData = data.sort((a, b) => {
    if (
      orderBy === 'caseNumb'
      // orderBy === 'subject' ||
      // orderBy === 'type' ||
      // orderBy === 'priority' ||
      // orderBy === 'caseNumb' ||
      // orderBy === 'date'
    ) {
      return order === 'asc'
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    } else {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
  });
  // Handle change of page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Handle change of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addDocument = () => {
    navigate('AddCase');
  };
  const handleRowClick = (row) => {
    setDownload(row.id);
    const currentIndex = selected.indexOf(row.id);
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(row.id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    setIsAnySelected(newSelected.length > 0);
  };
  // const alertDel = () => {
  //   alert('Confirmation For Delete');
  // };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((data) => data.id);
      setSelected(newSelected);
      setIsAnySelected(true);
    } else {
      setSelected([]);
      setIsAnySelected(false);
    }
    setAllSelected(event.target.checked);
    return false;
  };
  const buttonClassName = isAnySelected
    ? 'text-sm px-2 text-black'
    : 'text-sm px-2 text-gray-400';

  const svgStrokeColor = isAnySelected ? '#000000' : '#8D8D8D';

  const downloadCSV = () => {
    if (selected.length === 0) {
      return;
    }
    const downloadData = data.filter((row) => selected.includes(row.id));
    const csvData = new Blob([convertToCSV(downloadData)], {
      type: 'text/csv',
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `Cases.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  let valueToLabelFunc = (parm) => {
    const type = docTypeOptions.map((e) => {
      if (e.value == parm) {
        return e.label;
      }
    });
    return type;
  };

  const caseStatusOPtions = [
    {
      value: 'Open_New',
      label: 'New',
    },
    {
      value: 'Open_Assigned',
      label: 'Assigned',
    },
    {
      value: 'Closed_Closed',
      label: 'Closed',
    },
    {
      value: 'Open_Pending_Input',
      label: 'Pending Input',
    },
    {
      value: 'Closed_Rejected',
      label: 'Rejected',
    },
    {
      value: 'Closed_Duplicate',
      label: 'Duplicate',
    },
  ];
  const caseStatus = (key) => {
    const status = caseStatusOPtions.map((e) => {
      if (e.value == key) {
        return e.label;
      }
    });

    return status;
  };
  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        background: '#fff',
        borderRadius: '15px',
        boxShadow: '0px 0px 25px rgba(16, 69, 249, 0.1)',
        fontFamily: 'inherit',
        marginBottom: '20px',
        marginTop: '32px',
      }}
    >
      <TableContainer>
        <div className="flex justify-between md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
          <Typography
            sx={{
              flex: '1 1 100%',
              textAlign: 'left  ',
              fontFamily: 'inherit',
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {lang('lblCases')}
          </Typography>
          {allSelected === true ? (
            <div
              onClick={() => {
                alert('Are you sure you want to del');
              }}
              className="cursor-pointer"
            >
              {/* <DeleteIcon /> */}
            </div>
          ) : (
            <div>
              
            </div>
          )}
        </div>
        <div className="add-document-heading"></div>
        <div className="flex gap-1 mt-5 ml-4">
          <div className="rounded-md mr-2 ml-4 px-2 py-1 flex">
           <button
                className="document-btn text-right"
                onClick={() => {
                  addDocument();
                }}
              >
                {lang('lblBtnAddCase')}
              </button>
          </div>
          {/* <div className="bg-slate-100 rounded-md mr-2 px-2 py-1 flex">
            {' '}
            <button className="text-sm px-2 text-gray-400">Delete</button>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#8D8D8D"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M12.9394 6.14964C12.9394 6.14964 12.5231 11.3127 12.2816 13.4875C12.1666 14.5263 11.525 15.135 10.474 15.1541C8.47392 15.1901 6.47156 15.1924 4.47227 15.1503C3.46112 15.1296 2.83021 14.5132 2.71752 13.4929C2.47451 11.2989 2.06055 6.14964 2.06055 6.14964"></path>
              <path d="M14 3.67467H1"></path>
              <path d="M11.4952 3.67467C10.8934 3.67467 10.3752 3.2492 10.2571 2.65969L10.0708 1.7275C9.95582 1.29744 9.56639 1 9.12253 1H5.87751C5.43365 1 5.04422 1.29744 4.92923 1.7275L4.74294 2.65969C4.62488 3.2492 4.10666 3.67467 3.50488 3.67467"></path>
            </svg>
          </div> */}
        </div>
        <div className="p-5">
          <Table>
            <TableHead style={{ borderBottom: '2px solid  gray' }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={allSelected}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'caseNumb'}
                    direction={orderBy === ' caseNumb' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'caseNumb')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblCaseNumb')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'subject'}
                    direction={orderBy === 'subject' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'subject')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblSubject')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Status'}
                    direction={orderBy === 'Status' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'Status')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblStatus')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'type'}
                    direction={orderBy === 'type' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'type')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblQuestionCategory')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <TableSortLabel
                    active={orderBy === 'priority'}
                    direction={orderBy === 'priority' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'priority')}
                    style={{ fontWeight: '600', marginLeft: '16px' }}
                  >
                    {lang('lblPriority')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'date')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblDateCreated')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontWeight: '600' }}>
                  {lang('lblAction')}
                </TableCell>
              </TableRow>
            </TableHead>

            {!isLoading ? (
              <div
                style={{
                  padding: '20px',
                  margin: '20px',
                  position: 'absolute',
                  left: '40%',
                }}
              >
                <CircularProgress /> {/* Loader while data is being fetched */}
              </div>
            ) : (
              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      style={{ cursor: 'pointer' }}
                      // onClick={() => handleRowClick(row)}
                    >
                      {/* <TableCell>{row.id}</TableCell> */}
                      <TableCell
                        component="th"
                        id={row.id}
                        scope="row"
                        padding="checkbox"
                      >
                        <Checkbox
                          color="primary"
                          checked={selected.indexOf(row.id) !== -1}
                          onChange={() => handleRowClick(row)}
                          inputProps={{
                            'aria-labelledby': row.id,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        // onClick={() => handlePrevCase(row)}
                        style={{
                          width: '11%',
                        }}
                      >
                        {row.caseNumb}
                      </TableCell>
                      <TableCell
                        onClick={() => handlePrevCase(row)}
                        style={{
                          width: '14%',
                        }}
                      >
                        {row.subject}
                      </TableCell>
                      <TableCell
                        // onClick={() => handlePrevCase(row)}
                        style={{
                          width: '10%',
                        }}
                      >
                        {caseStatus(row.Status)}
                      </TableCell>
                      <TableCell>{valueToLabelFunc(row.type)}</TableCell>
                      <TableCell>
                        <div className="td-1" id="td-priority-md">
                          <p className={`td-` + row.priority}>
                            {getPriorityLabel(row.priority)}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {row.date}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-5">
                         {/* <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#8D8D8D"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="hover:stroke-blue-600 stroke-2"
                            onClick={() => {
                              handleEditCase(row);
                            }}
                          >
                            <path d="M8.35857 1.03249H5.21127C2.62289 1.03249 1 2.86497 1 5.45924V12.4575C1 15.0518 2.61532 16.8843 5.21127 16.8843H12.6389C15.2357 16.8843 16.851 15.0518 16.851 12.4575V9.06698"></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.11461 7.87751L12.405 1.58712C13.1887 0.804293 14.4589 0.804293 15.2425 1.58712L16.2669 2.61153C17.0506 3.3952 17.0506 4.66624 16.2669 5.44906L9.94625 11.7698C9.60366 12.1123 9.13902 12.3051 8.65417 12.3051H5.50098L5.5801 9.1233C5.59189 8.65528 5.78296 8.20916 6.11461 7.87751Z"
                            ></path>
                            <path d="M11.4502 2.55901L15.2936 6.40243"></path>
                            <title>{lang('lblEditCase')}</title>
                          </svg> */}

                          {row.hasFileContent === true ? (
                            <a
                              href={`${config.API_BASE_URL}/index.php?preview=yes&entryPoint=viewDocument&id=${row?.document_revision_id}&type=Documents`}
                              download={row?.fileName}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#8D8D8D"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="hover:stroke-blue-600 stroke-2"
                              >
                                <path d="M10.1021 12.8634L10.1021 2.82922"></path>
                                <path d="M12.5322 10.4236L10.1022 12.8636L7.67223 10.4236"></path>
                                <path
                                  opacity="0.7"
                                  d="M13.9626 6.77332H14.7401C16.4359 6.77332 17.8101 8.14748 17.8101 9.84415V13.9141C17.8101 15.6058 16.4392 16.9766 14.7476 16.9766H5.46423C3.76839 16.9766 2.39339 15.6016 2.39339 13.9058L2.39339 9.83498C2.39339 8.14415 3.76506 6.77332 5.45589 6.77332L6.24089 6.77332"
                                ></path>
                              </svg>
                            </a>
                          ) : (
                            ''
                          )}

                          <svg
                            width="22"
                            height="18"
                            viewBox="0 0 22 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#8D8D8D"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="hover:stroke-blue-600 stroke-2"
                            onClick={() => handlePrevCase(row)}
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.4177 8.89427C14.4177 10.7818 12.8869 12.3116 10.9993 12.3116C9.11176 12.3116 7.58203 10.7818 7.58203 8.89427C7.58203 7.00562 9.11176 5.47589 10.9993 5.47589C12.8869 5.47589 14.4177 7.00562 14.4177 8.89427Z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.9978 16.7881C15.1146 16.7881 18.88 13.8281 21 8.89405C18.88 3.96 15.1146 1 10.9978 1H11.0022C6.8854 1 3.12 3.96 1 8.89405C3.12 13.8281 6.8854 16.7881 11.0022 16.7881H10.9978Z"
                            ></path>
                            <title>{lang('lblViewCase')}</title>
                          </svg>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </div>
      </TableContainer>
      {/* Pagination Controls */}

      {isLoading && (
        <TablePagination
          component="div"
          count={totalCount} // The total number of rows (e.g., from API)
          page={page} // Current page
          onPageChange={handleChangePage} // Function to handle page change
          rowsPerPage={rowsPerPage} // Number of rows per page
          onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changing rows per page
          rowsPerPageOptions={[10, 25, 50, 100]} // The options for number of rows per page
          labelRowsPerPage={lang('lblFooterRowPage')}
        />
      )}
    </Paper>
  );
};
export default MUITableComponent;
