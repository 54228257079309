import React from 'react';

const ForgotPassword = () => {
  return (
    <>
      <div id="container">
        <div id="login">
          <img src="https://portaldemo.crmjetty.com/suite-wp-portal-demo/wp-content/uploads/2024/08/suitecrm-logo.png"></img>

          <p className="portal">Forgot Password</p>
          <input
            type="text"
            placeholder="Enter your email"
            className="signinjs"
          ></input>

          <div>
            <div className="footer">
              <p>Did not get Email?</p>
              <a href="#">Resend</a>
            </div>
            <div>
              <a
                className="footer"
                href="#"
                onClick={() => {
                  // setAction(true);
                }}
              >
                Back To Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
