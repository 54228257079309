import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config/config';
import { lang } from '../config/lang';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import backSvg from '../images/arrow-back-svgrepo-com.svg';
import { getDocuemtnTypeLabelByKey } from '../helpers/general-helper';
const Preview = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const doc = location?.state || {};
  const handleEditDocument = (data) => {
    navigate(`../documents/edit/${data.id}`, { state: { ...data } });
  };
  const [value, setValue] = React.useState('1');
  const [docTypeLoading, setDocTypeLoading] = React.useState(false);
  const [docTypeOptions, setDocTypeOptions] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDocumentTypeList = async () => {
    try {
      const resp = await getDocuemtnTypeLabelByKey();
      setDocTypeOptions(resp);
    } catch (er) {
      console.log(er);
    } finally {
      setDocTypeLoading(true);
    }
  };

  React.useEffect(() => {
    if (!docTypeLoading) {
      handleDocumentTypeList();
    }
  }, [docTypeLoading]);

  const handleKeyToLabel = (key) => {
    const data = docTypeOptions;
    let lblType = '';
    for (let i in data) {
      if (data[i].value == key) {
        lblType = data[i].label;
        break;
      }
    }
    return lblType;
  };

  return (
    <form method="post">
      <div className="add-document-main-div">
        <div className="add-document-inner-div">
          <div className="flex   justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            <h2 className="text-left h-auto font-medium text-xl">
              {lang('lblPrevDoc')}
            </h2>
            <div className="add-doc-btn">
              <div className=" px-3">
              </div>
            </div>
          </div>
          <div className="add-document-heading"></div>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={
                      <span className="font-medium ">Document Details</span>
                    }
                    value="1"
                    sx={{
                      '&.Mui-selected': {
                        color: 'blue',
                        borderColor: 'blue',
                      },
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="add-document-inputs">
                  <div className="document-name1 ">
                    <label className="add-doc-label mt-1 font-bold w-48">
                      {lang('lblDocumentName')}
                      <span className="">:</span>
                    </label>
                    <div className=" text-sm w-full bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 text-left">
                      {doc?.documentName || ''}
                    </div>
                  </div>
                  {/* <div className="document-name1 ">
                    <label
                      htmlFor="type"
                      className="text-left text-sm mt-1 font-bold w-28"
                    >
                      {lang('lblType')}
                      <span className="">:</span>
                    </label>
                    <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                      {' '}
                      {doc?.type || ''}
                    </div>
                  </div> */}
                  <div className="document-name1 ">
                    <label
                      htmlFor="type"
                      className="add-doc-label mt-1 text-left text-sm mb-2 font-bold w-28"
                    >
                      {lang('lblType')}
                      <span className="">:</span>
                    </label>
                    <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                      {' '}
                      {handleKeyToLabel(doc.type) || ''}
                    </div>
                  </div>

                  {/* <div className="document-name ">
              <label className="add-doc-label font-bold">
                Publish Date <span className="">: </span>
                <span className="ml-2">
                  {new Date(doc?.dateCreated).toLocaleDateString('en-GB') || ''}
                </span>
              </label>
            </div>
            <div className="document-name ">
              <label className="add-doc-label font-bold">
                Expiration Date <span className="">: </span>
                <span className="ml-2">
                  {new Date(doc?.dateExpired || '').toLocaleDateString('en-GB')}
                </span>
              </label>
            </div> */}
                  <div className="document-name1 ">
                    <label className="add-doc-label mt-1 font-bold w-48">
                      {lang('lblFileName')} <span className="">: </span>{' '}
                    </label>
                    <div className=" text-sm w-full bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200 ">
                      {' '}
                      <a
                        className=" cursor-pointer flex w-full"
                        href={`${config.API_BASE_URL}${config.PREVIEW_DOC_LINK} id=${doc?.document_revision_id}&type=Documents`}
                        download={doc?.fileName}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {doc?.fileName || ''}{' '}
                        <span className="ml-3">
                          {' '}
                          <svg
                            width="22"
                            height="18"
                            viewBox="0 0 22 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#8D8D8D"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="hover:stroke-blue-600 stroke-2"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.4177 8.89427C14.4177 10.7818 12.8869 12.3116 10.9993 12.3116C9.11176 12.3116 7.58203 10.7818 7.58203 8.89427C7.58203 7.00562 9.11176 5.47589 10.9993 5.47589C12.8869 5.47589 14.4177 7.00562 14.4177 8.89427Z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.9978 16.7881C15.1146 16.7881 18.88 13.8281 21 8.89405C18.88 3.96 15.1146 1 10.9978 1H11.0022C6.8854 1 3.12 3.96 1 8.89405C3.12 13.8281 6.8854 16.7881 11.0022 16.7881H10.9978Z"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className="document-name1 ">
                    <label className="add-doc-label font-bold w-28">
                      {lang('lblNote')} <span className="">:</span>
                    </label>
                    <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                      {doc?.description || ''}
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </form>
  );
};

export default Preview;
