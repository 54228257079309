import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { getFormatedDateTime } from '../helpers/dt-helper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { getUsernotification } from '../controllers/notification';

import { lang } from '../config/lang';
const MUITableComponent = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [totalCount, setTotalCount] = useState(0); // Total number of records for pagination

  // State for sorting
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateCreated'); // Default sort by "id"
  // Fetch data from API when the component mounts
  const fetchData = async (p, rp) => {
    try {
      setIsLoading(true);
      let rows = [];
      const notify = await getUsernotification();
      rows = notify?.data?.data.map((doc) => {
        const { date_entered, name } = doc.attributes;
        return {
          dateCreated: getFormatedDateTime(date_entered),
          name: name,
        };
      });
      setData(rows);
      setTotalCount(notify?.data?.data.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  // Handle sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // Sort the data based on the order and orderBy state
  const sortedData = data.sort((a, b) => {
    if (orderBy === 'dateCreated' || orderBy === 'name') {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    } else {
      return order === 'asc'
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    }
  });
  // Handle change of page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    const currentIndex = selected.indexOf(row.id);
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(row.id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
  };

  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        background: '#fff',
        borderRadius: '15px',
        boxShadow: '0px 0px 25px rgba(16, 69, 249, 0.1)',
        marginBottom: '20px',
        marginTop: '32px',
        fontFamily: 'inherit',
      }}
    >
      <TableContainer>
        <div className="flex justify-between md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
          <Typography
            sx={{
              flex: '1 1 100%',
              textAlign: 'left  ',
              fontFamily: 'inherit',
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {lang('lblNotification')}
          </Typography>
        </div>
        <div className="add-document-heading"></div>
        <div className="p-5">
          <Table>
            <TableHead style={{ borderBottom: '2px solid  gray' }}>
              <TableRow>
                <TableCell
                  onClick={(event) => handleRequestSort(event, 'documentName')}
                  style={{
                    fontWeight: '500',
                    fontSize: '17px',
                  }}
                >
                  {lang('lblNotification')}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'dateCreated'}
                    direction={orderBy === 'dateCreated' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'dateCreated')}
                    style={{
                      fontWeight: '400',
                      fontSize: '16px',
                      marginRight: '48px',
                    }}
                  >
                    {lang('lblNotifiDate')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <div
                style={{
                  padding: '20px',
                  margin: '20px',
                  position: 'absolute',
                  left: '40%',
                }}
              >
                <CircularProgress /> {/* Loader while data is being fetched */}
              </div>
            ) : (
              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(row)}
                    >
                      <TableCell>{row.name}</TableCell>

                      <TableCell style={{ textAlign: 'center' }}>
                        {row.dateCreated}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </div>
      </TableContainer>
      {/* Pagination Controls */}
      {!isLoading && (
        <TablePagination
          component="div"
          count={totalCount} // The total number of rows (e.g., from API)
          page={page} // Current page
          onPageChange={handleChangePage} // Function to handle page change
          rowsPerPage={rowsPerPage} // Number of rows per page
          onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changing rows per page
          rowsPerPageOptions={[5, 10, 25, 50, 100]} // The options for number of rows per page
          labelRowsPerPage={lang('lblFooterRowPage')}
        />
      )}
    </Paper>
  );
};
export default MUITableComponent;
