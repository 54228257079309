import api from '../api';

export const getAllInvoices = () => {
  try {
    const user_id = localStorage.getItem('user_id') || null;
    const response = api.get(
      `/Api/V8/module/Contacts/${user_id}/relationships/aos_invoices`
    );
    return response;
  } catch (er) {
    throw er;
  }
};
