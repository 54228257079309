import React, { useState, useEffect, useRef } from 'react';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { initFlowbite } from 'flowbite';

import UserProfile from './userprofile';
import Logout from './logout';
import pic1 from '../images/ankerhuis-logo-main.png';
import pic2 from '../images/cherrywood-logo-main.png';
import pic3 from '../images/villa-logo-main.png';
import defaultPic from '../images/Salam.png';
import { Translate } from '@mui/icons-material';
import { lang } from '../config/lang';
import userSvg from '../images/user-svgrepo-com.svg';
import powerBtn from '../images/power-material-svgrepo-com.svg';
import DrpDwnBtn from '../images/chevron-down-svgrepo-com.svg';
import { getUsernotification } from '../controllers/notification';

const Header = (props) => {
  const navigate = useNavigate();
  const [headerLogo, setHeaderLogo] = useState();
  const { toggleSideBar } = props;
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const authUsername = localStorage.getItem('user_name') || '';
  const dropdownRef = useRef(null);
  const [data, setData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [hasReadNotifications, setHasReadNotifications] = useState(false);
  useEffect(() => {
    initFlowbite();
    const treatmentFacility = localStorage.getItem('treatment_facility');
    if (treatmentFacility === 'VC') {
      setHeaderLogo(pic3);
    } else if (treatmentFacility === 'AH') {
      setHeaderLogo(pic1);
    } else if (treatmentFacility === 'CWH') {
      setHeaderLogo(pic2);
    } else {
      setHeaderLogo(defaultPic);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSidebar = () => {
    setSidebarStatus(!sidebarStatus);
    toggleSideBar(!sidebarStatus);
  };

  const handleNavigation = (page) => {
    navigate(`/${page}`);
    setIsProfileDropdownOpen(false);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen((prev) => !prev);
  };
  const handleNotification = () => {
    navigate(`notification`);
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const notify = await getUsernotification();
      const rows = notify?.data?.data || []; // Get data or default to an empty array

      // Update notification count only if not read
      if (!hasReadNotifications) {
        setNotificationCount(rows.length); // Update the count state
      } else {
        setNotificationCount(rows.length > 0 ? rows.length : 0);
      }

      setData(rows);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationClick = () => {
    setHasReadNotifications(true);
    setNotificationCount(0);
    localStorage.setItem('hasReadNotifications', 'true');
  };

  useEffect(() => {
    const readStatus = localStorage.getItem('hasReadNotifications') === 'true';
    setHasReadNotifications(readStatus);

    fetchData();
  }, []);
  return (
    <>
      <div
        className={`h-full left-0 flex flex-row my-auto pt-0 bg-white ${
          !sidebarStatus ? '' : 'w-full'
        }`}
        style={{ marginLeft: '0px', marginRight: '0px' }}
      >
        <div className="pt-3 pl-4 pb-4  md:hidden">
          <button className="" onClick={handleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="24"
              viewBox="0 0 39 24"
              fill="none"
              className=" md:ml-20"
            >
              <path
                d="M31.3511 10H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M35.3511 6H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M35.3511 14H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M31.3511 18H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.6875 16.875L5.3125 12.5L9.6875 8.125"
                stroke="#8B8B8B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="py-1 md:h-auto xs:h-12 xs:mt-1 md:mt-0 md:w-56 xs:w-34  md:ml-3 xs:ml-1 bg-contain flex align-middle justify-center">
          <img src={headerLogo} alt="Logo" />
        </div>
        <div className="h-16 pt-1  pb-4  xs:hidden md:block">
          <button className="" onClick={handleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="24"
              viewBox="0 0 39 24"
              fill="none"
              className="md:my-4 ml-3"
            >
              <path
                d="M31.3511 10H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M35.3511 6H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M35.3511 14H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M31.3511 18H17.3511"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.6875 16.875L5.3125 12.5L9.6875 8.125"
                stroke="#8B8B8B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          onClick={handleNotificationClick}
          className="w-max my-auto ms-auto me-8 gap-2 flex flex-row align-middle justify-center relative"
        >
          <button
            id="dropdownDefaultButton1"
            data-dropdown-toggle="dropdown1"
            className="w-6 h-6 xs:hidden md:block relative"
            onClick={handleNotification}
          >
            <img
              alt="icon"
              className="w-full h-full object-fill rounded-md mt-3 "
              src="https://portaldemo.crmjetty.com/suite-wp-portal-demo/wp-content/plugins/biztech-crm-portal-suite_v4.3.1/assets/images/Notification.svg"
            />
            {notificationCount > 0 && (
              <span className="absolute top-1 left-3 bg-red-500 text-white rounded-full px-1 text-sm">
                {notificationCount}
              </span>
            )}
          </button>
          <div className="rounded-xl h-max bg-slate-100 flex flex-row w-max gap-2 align-middle justify-center px-2 py-2">
            <h3 className="text-sm p-1 xs:hidden md:block">
              {lang('lblGreet')}
              <span className="font-bold ml-2">{authUsername}</span>
            </h3>
            <button
              onClick={toggleProfileDropdown}
              className="bg-gray-300 w-8 h-8 rounded-full flex items-center justify-center"
            >
              <img
                alt="Profile"
                className="w-full h-full object-fill rounded-xl right-0"
                src="https://secure.gravatar.com/avatar/c8c2458581fb567d3c1f5b040b28f3af?s=51&d=mm&r=g"
              />
            </button>
            <img
              onClick={toggleProfileDropdown}
              src={DrpDwnBtn}
              alt=""
              className="w-5 cursor-pointer"
            />
          </div>

          <div
            id="dropdown"
            ref={dropdownRef}
            className={`z-10 ${
              isProfileDropdownOpen ? 'block' : 'hidden'
            } absolute right-0 md:mt-14 xs:mt-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-56`}
          >
            <ul
              className="py-2 text-sm text-gray-700"
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <button
                  onClick={() => handleNavigation('user/profile')}
                  className="px-4 py-2 hover:bg-gray-100 w-full text-left flex justify-between"
                >
                  {lang('lblViewProf')}
                  <img src={userSvg} className="w-4"></img>
                </button>
              </li>
              {/* <li>
                <button
                  onClick={() => handleNavigation('user/ChangePassword')}
                  className="px-4 py-2 hover:bg-gray-100 w-full text-left"
                >
                  Change Password
                </button>
              </li> */}
              <li>
                <button
                  onClick={() => handleNavigation('logout')}
                  className="px-4 py-2 hover:bg-gray-100 w-full text-left flex justify-between"
                >
                  {lang('lblLogOut')}
                  <img src={powerBtn} className="w-4"></img>
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* Notification dropdown */}
      </div>
    </>
  );
};

export default Header;
