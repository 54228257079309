import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Header from '../../components/Header';
import AdminSidebarIcons from './AdminSidebarIcons';

const AdminDashboard = () => {
  const [isSidebarToggled, setSidebarToggled] = React.useState(false);
  // Function to toggle the sidebar manually
  const toggleSideBar = () => {
    setSidebarToggled((prev) => !prev);
  };
  // const isSmallScreen = window.innerWidth < 768;
  // Function to handle window resize
  const handleResize = () => {
    if (window.innerWidth > 700) {
      setSidebarToggled(false);
    } else {
      setSidebarToggled(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="bg-slate-100">
      <div
        className={`absolute md:top-16 xs:top-14 left-0 z-40 w-60 h-full overflow-y-auto transition-transform  ${
          isSidebarToggled ? '-translate-x-full' : 'translate-x-0'
        }`}
      >
        <AdminSidebar toggleSideBar={toggleSideBar} />
      </div>
      {isSidebarToggled && (
        <div className={`absolute top-14 left-0 w-28 z-40`}>
          <AdminSidebarIcons />
        </div>
      )}
      <Header toggleSideBar={toggleSideBar} className={'fixed'} />
      {/* Position relative so the toggle can come in front */}
      <div
        id="main"
        className={`flex flex-col flex-grow w-full ${
          isSidebarToggled
            ? 'xs:-translate-x-64 md:-translate-x-44'
            : 'translate-x-0'
        }`}
      >
        <main
          className={`sticky  h-full bg-cover p-6 pt-0 ${
            !isSidebarToggled ? 'md:ml-64' : 'xs:w-full md:w-auto xs:ml-64'
          }`}
          style={
            {
              // marginLeft: '250px',
              // marginBottom: isSmallScreen ? '435px ' : '0px',
            }
          }
        >
          <div>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
