import { en } from '../languages/English.js';
import { fr } from '../languages/French.js';
import { nl } from '../languages/Dutch.js';

const ln = () => {
  return localStorage.getItem('user_lang') || 'en';
};

export const lang = (param) => {
  const lang = ln();
  switch (lang) {
    case 'French':
      return fr[param];
      break;
    case 'Dutch':
      return nl[param];
      break;
    case 'English':
      return en[param];
    default:
      return en[param];
      break;
  }
};
