import { useLocation, Navigate } from "react-router-dom";
import isTokenExpired from "../utils/isTokenExpired";
const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    if (isTokenExpired() ===  true) {
      return <Navigate to="/logout" state={{ from: location }} replace />;
    }
  
    return children;
  };
  export default ProtectedRoute

