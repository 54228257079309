import React from 'react';

const Card = (props) => {
  const { title, pic, bgcolor, contentText } = props;

  return (
    <div>
      <div
        className={` flex flex-col   py-4 px-2 max-h-60 max-w-90 items-center border rounded-3xl  transition duration-300 hover:bg-blue-600 text-gray-600  hover:opacity-100 hover:text-white shadow-lg bg-white`}
      >
        <div
          className={`${
            bgcolor || ''
          }  rounded-full h-24 w-24 flex justify-center items-center`}
        >
          <img
            src={pic}
            alt="img"
            sizes=""
            srcSet=""
            className=" bg-contain h-20 w-20"
          />
        </div>
        <p className={`text-base font-medium  mt-4`}>{title}</p>
        <div className="mt-auto">
          <div className="font-bold text-5xl  ">{contentText || '0'}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
