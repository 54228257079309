import React, { useState } from 'react';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCasePriorityOptions, updateCase } from '../controllers/cases';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { lang } from '../config/lang';
import { getCasesTypeList } from '../controllers/cases';
import {
  getPriorityLabelByKey,
  getTypeLabelByKey,
} from '../helpers/general-helper';
const EditCase = () => {
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [loading, setLoading] = React.useState(false);
  const [isTypeInvalid, setIsTypeInvalid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cases = location.state || {};
  const [prioritySelection, setPrioritySelection] = useState(cases?.priority);
  // const [caseId, setCaseId] = useState(cases?.id);
  const [subject, setSubject] = useState(cases?.subject || cases?.name);
  const [type, setType] = useState(cases.type || '');
  const [typeLabel, setTypeLabel] = useState(cases?.type);
  const [priority, setPriority] = useState(cases?.priority);
  const [priorityLabel, setPriorityLabel] = useState(
    getPriorityLabelByKey(cases?.priority)
  );
  const [description, setDescription] = useState(cases?.description);
  const [caseOptions, setCaseOpions] = useState([]);
  const [caseOptionsLoading, setCaseOpionsLoading] = useState(false);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const priorityChange = (selectedOption) => {
    setPrioritySelection(selectedOption.value);
    setPriorityLabel(selectedOption.label);
  };
  const getCaseOptions = async () => {
    try {
      const typeList = getCasesTypeList();
      setCaseOpions(typeList);
      const resType = await getTypeLabelByKey(cases.type);
      setTypeLabel(resType);
    } catch (e) {
      console.log('error', e);
    } finally {
      setCaseOpionsLoading(true);
    }
  };
  React.useEffect(() => {
    if (!caseOptionsLoading) {
      getCaseOptions();
      const pOptions = getCasePriorityOptions();
      setPriorityOptions(pOptions);
    }
  }, [caseOptionsLoading]);
  // Set selected option based on the type value
  const selectedOption = caseOptions.find((option) => option.value === type);

  const typeChange = (selectedOption) => {
    setType(selectedOption.value); // Store the value directly
    setTypeLabel(selectedOption.label); // Store the value directly
  };

  const handleKeyToLabel = (key) => {
    const data = caseOptions;
    let lblType = '';
    for (let i in data) {
      if (data[i].value == key) {
        lblType = data[i].label;
        break;
      }
    }
    return lblType;
  };

  const haldleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      setAlert({
        message: lang('lblinprocessEditCase'),
        severity: 'info',
      });
      const data = {
        caseId: cases?.id || '',
        subject: subject || '',
        type: type || '',
        priority: prioritySelection || priority,
        description: description || '',
      };
      const response = await updateCase(data);
      if (response?.status === 200) {
        setAlert({
          message: lang('lblsuccessEditCase'),
          severity: 'success',
        });
        navigate(-1);
      }
    } catch (err) {
      console.log('server:err', err.message);

      setAlert({ message: lang('lblFailEditCase'), severity: 'error' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <form method="post" onSubmit={haldleFormSubmit}>
      <div className="add-document-main-div">
        <div className="add-document-inner-div">
          <div className="flex flex-col font-medium justify-between rounded-t-3xl  md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            {alert.message && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
              </Stack>
            )}

            <h2 className="text-left h-auto   text-xl">
              {lang('lblEditCase')}
            </h2>
          </div>
          <div className="add-document-heading"></div>
          <div className="add-document-inputs">
            <div className="document-name">
              <label className="add-doc-label">
                {lang('lblSubject')} <span className="add-doc-span">*</span>
              </label>
              <input
                type="text"
                name="subject"
                className="add-doc-name-input"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </div>

            <div className="document-name text-left">
              <label className="add-doc-label">
                {' '}
                {lang('lblPriority')} <span className="add-doc-span">*</span>
              </label>
              <Select
                id="prioritySelection"
                name="prioritySelection"
                // ${ isPriorityInvalid ? 'border-red-600' : '' }
                className={`border rounded-xl h-10 w-full shadow-lg  `}
                placeholder={priorityLabel}
                options={priorityOptions}
                onChange={priorityChange}
                value={prioritySelection}
                maxMenuHeight={150}
                isClearable={false}
                isSearchable={false}
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'black',
                    padding: '0px',
                    textAlign: 'left',
                  }),
                }}
              />
            </div>

            {/* <div className="document-name" onClick={handleInputClick}>
            <label className="add-doc-label">
              Publish Date <span className="add-doc-span">*</span>
            </label>
            <input
              ref={inputRef}
              type="date"
              name="publishDate"
              className="add-doc-name-input"
              value={datePublish}
              onChange={(e) => setDatePublish(e.target.value)}
            />
          </div>
          <div className="document-name" onClick={handleExpirationDateClick}>
            <label className="add-doc-label">Expiration Date</label>
            <input
              ref={expirationDateRef}
              name="expirationDate"
              type="date"
              className="add-doc-name-input"
              value={dateExpire}
              onChange={(e) => setDateExpire(e.target.value)}
            />
          </div> */}
            <div className="document-name">
              <label htmlFor="type" className="text-left text-sm mb-2 ">
                {lang('lblQuestionEditCase')}
              </label>
              <Select
                id="type"
                name="type"
                placeholder={handleKeyToLabel(type)}
                className={`border rounded-xl h-10 text-left  w-full shadow-lg ${
                  isTypeInvalid ? 'border-red-600' : ''
                }`}
                options={caseOptions}
                isClearable={false}
                isSearchable={false}
                onChange={typeChange}
                value={type}
                maxMenuHeight={150}
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'black',
                    padding: '0px',
                    textAlign: 'left',
                  }),
                }}
              />
            </div>

            <div className="document-name">
              <label className="add-doc-label">{lang('lblNote')}</label>
              <textarea
                name="description"
                className="add-doc-textarea"
                onChange={(e) => setDescription(e.target.value)}
              >
                {description || ''}
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="add-doc-btn">
        <div className="add-btn">
          <button className="add-doc-inner-btn1" type="submit">
            {loading ? lang('lblUpdate') : lang('lblBtnSendQuestion')}
          </button>
          <button
            className="add-doc-inner-btn2"
            onClick={() => navigate('/cases')}
          >
            {lang('lblBtnCancel')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditCase;
