import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import AdminUserSidebar from './AdminUserSidebar';
import Header from '../../components/Header';

const AdminDashboard = () => {
  return (
    <div className="h-screen bg-slate-100">
      <div>
        <AdminUserSidebar />
      </div>
      <div className="flex flex-col flex-grow overflow-hidden">
        <Header />
        <main
          className="flex-grow overflow-x-hidden h-screen overflow-y-auto p-6 pt-0"
          style={{
            marginLeft: '176px',
            marginRight: '-28px',
            marginBottom: '-25px',
          }}
        >
          <div>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
