import { getDocumentTypeList } from '../controllers/documents';
import { lang } from '../config/lang';
const priotiryOptions = [
  { label: lang('lblPriorityHigh'), value: 'P1' },
  { label: lang('lblPriorityMed'), value: 'P2' },
  { label: lang('lblPriorityLow'), value: 'P3' },
];

export const getPriorityLabelByKey = (key) => {
  for (let index in priotiryOptions) {
    if (priotiryOptions[index].value == key) {
      return priotiryOptions[index].label;
    }
  }

  return key;
};

export const getTypeLabelByKey = async (key) => {
  try {
    const data = await getDocumentTypeList();
    for (let i in data) {
      if (data[i].value == key) {
        return data[i].label;
      }
    }
    return key;
  } catch (er) {
    return er;
  }
};

export const getDocuemtnTypeLabelByKey = () => {
  const documentType = [
    { label: lang('lblPid'), value: 'PID' },
    { label: lang('lblInvoices'), value: 'Invoice' },
    { label: lang('lblTreatmentPlan'), value: 'Treatment_plan' },
    { label: lang('lblIntake'), value: 'Intake report' },
    { label: lang('lblMate'), value: 'MATE' },
    { label: lang('lblDefense'), value: 'Defense_letter' },
    { label: lang('lblAppForInsurance'), value: 'pplication_for_insurance' },
    { label: lang('lblDischarge'), value: 'Discharge_report' },
  ];
  return documentType;
};
