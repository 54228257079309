import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MemberProfile = () => {
    const location = useLocation();
    const data = location.state;

    const [isEditing, setIsEditing] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [olduserEmail, setOldUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();

    const fileTicket = () => {
        navigate('/fileTicket', { state: data }); // Navigate to the registration route
    };
    const viewMeetings = () => {
        navigate('/ViewMeetings', { state: data }); // Navigate to the registration route
    };
    const createMeetings = () => {
        navigate('/createMeet', { state: data }); // Navigate to the registration route
    };
    const resolveTickets = () => {
        navigate('/resolveTickets', { state: data }); // Navigate to the registration route
    };


    useEffect(() => {
        // Initialize state with received data
        const userData = localStorage.getItem('user');
        const parsedUserData = JSON.parse(userData);
        const userId = parsedUserData._id;
        if (parsedUserData) {
            setUserName(parsedUserData.name || '');
            setUserEmail(parsedUserData.email || '');
            setOldUserEmail(parsedUserData.email || '');
            setUserRole(parsedUserData.role || '');
        }
    }, [data]);

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
    };

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };



    function isValidPassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("New passwords do not match!");
            return;
        }

        else if (!isValidPassword(newPassword)) {
            alert('Password does not meet security criteria.');
            event.preventDefault();
        }
        else {
            try {

                const token = localStorage.getItem('key');
                console.log("Token", token)              
                const response = await fetch('/api/client/updateProfile', { 
                    method: 'PATCH',

                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userName,
                        userEmail,
                        oldPassword,
                        newPassword,
                        olduserEmail: olduserEmail,
                        olduserPassword: oldPassword,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Profile update failed');
                }

                const result = await response.json();
                console.log('Profile updated successfully:', result);

                // Optionally reset the form and switch back to view mode
                setIsEditing(false);

            } catch (error) {
                console.error('Error updating profile:', error.message);
            }
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="name" className="block text-start text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                autoComplete="name"
                                value={userName}
                                onChange={handleUserNameChange}
                                disabled={!isEditing}
                                className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-start text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                value={userEmail}
                                onChange={handleEmailChange}
                                disabled={!isEditing}
                                className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {isEditing && (
                        <>
                            <div>
                                <label htmlFor="oldPassword" className="block text-start text-sm font-medium leading-6 text-gray-900">
                                    Old Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="oldPassword"
                                        name="oldPassword"
                                        type="password"
                                        required
                                        autoComplete="current-password"
                                        value={oldPassword}
                                        onChange={handleOldPasswordChange}
                                        className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="newPassword" className="block text-start text-sm font-medium leading-6 text-gray-900">
                                    New Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="newPassword"
                                        name="newPassword"
                                        type="password"
                                        required
                                        autoComplete="new-password"
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                        className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="confirmPassword" className="block text-start text-sm font-medium leading-6 text-gray-900">
                                    Confirm Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        required
                                        autoComplete="new-password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div>
                        <button
                            type={!isEditing ? 'submit' : 'button'}
                            onClick={() => setIsEditing(!isEditing)}
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {isEditing ? 'Submit' : 'Update Profile'}
                        </button>
                    </div>
                </form>




            </div>

            {/* {userRole === 'admin' ? (<div className='sm:mx-auto sm:w-full sm:max-w-sm mt-5 flex flex-row xs:flex-col justify-around'>
                <button
                    type='button'
                    onClick={viewMeetings}
                    className="rounded-md bg-white px-3 py-1.5 w-36 text-sm font-semibold leading-6 text-indigo-600 shadow-sm  focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-8 focus-visible:outline-indigo-600"
                >
                    View Meetings
                </button>
                <button
                    type='button'
                    onClick={fileTicket}
                    className="rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm w-36 focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-8 focus-visible:outline-indigo-600"
                >
                    File Tickets
                </button>
            </div>) :
                (<div className='sm:mx-auto sm:w-full sm:max-w-sm mt-5 flex flex-row xs:flex-col justify-around'>
                    <button
                        type='button'
                        onClick={createMeetings}
                        className="rounded-md bg-white px-3 py-1.5 w-36 text-sm font-semibold leading-6 text-indigo-600 shadow-sm  focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-8 focus-visible:outline-indigo-600"
                    >
                        Create Meetings
                    </button>
                    <button
                        type='button'
                        onClick={resolveTickets}
                        className="rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm w-36 focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-8 focus-visible:outline-indigo-600"
                    >
                        Resolve Ticket
                    </button>
                </div>)} */}
        </div>
    );
};

export default MemberProfile;
