import api from '../api';
import config from '../config/config';
import axios from 'axios';

export const getUserDocuments = async () => {
  try {
    const filters = await documentFilterParams();
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const url = `/Api/V8/module/Documents?filter[operator]=and&filter[contact_to_doc_id][eq]=${user_id}&filter[share_with_client][eq]=1${filters}`;

    const response = await api.get(url);
    // const response = await api.get(`/Api/V8/module/Contacts/${user_id}/relationships/documents`);

    return response;
  } catch (error) {
    const err = error?.response?.data ? error?.response?.data : error;
    throw err;
  }
};

export const getDocumentsWithLimit = async (page, rowsPerPage) => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const response = await api.get(
      `/Api/V8/module/Contacts/${user_id}/relationships/documents?page[number]=${
        page + 1
      }&page[size]=${rowsPerPage}`
    );

    return response;
  } catch (error) {
    const err = error?.response?.data ? error?.response?.data : error;
    throw err;
  }
};

export const createDocument = async (data) => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!data) throw { message: 'Please provide the document data' };
    const documents = {
      data: {
        type: 'Documents',
        attributes: {
          contact_to_doc_id: user_id,
          share_with_client: 1,
          name: data.docName,
          document_name: data.docName,
          file_ext: data?.file?.name.split('.').pop() || 'pdf',
          file_mime_type: data?.file?.type || 'application/pdf',
          filename: data?.file?.name || '',
          template_type: data?.doc_type || '', //type field in Portal
          description: data?.description || '',
          filecontents: data?.fileBase64 || '',
        },
      },
    };
    const response = await api.post(`/Api/V8/module`, documents);
    //   if(response.status === 201){
    //       const linkDocsClient = {
    //           "data": {
    //                     "type": "Documents",
    //                     "id": response.data.data.id
    //                   }
    //       }
    //
    //   const response2 = await api.post(`/Api/V8/module/Contacts/${user_id}/relationships/documents`, linkDocsClient);
    //   return response2;
    // }

    return response;
  } catch (error) {
    return error;
  }
};

export const updateDocument = async (data) => {
  try {
    if (!data?.id) throw { message: 'Required option id is not found..' };

    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'User not found..' };
    const token = localStorage.getItem('token');
    if (!token) throw { error: 'Session Expired: please login again' };
    const token_type = localStorage.getItem('token_type') || 'Bearer';

    let file_ext =
      data?.filename != '' ? data.filename.split('.').pop() : 'pdf';
    file_ext = data?.file?.name ? data.file.name.split('.').pop() : file_ext;
    const file_mime_type = `application/${file_ext}`;
    const filename = data?.file?.name ? data?.file?.name : data?.filename;
    const filecontents = data.fileBase64
      ? `filecontents: ${data.fileBase64},`
      : '';
    let documents = {};
    if (data.fileBase64 != '') {
      documents = {
        user: {
          id: user_id,
          access_token: token,
          token_type: token_type,
        },
        data: {
          type: 'Documents',
          id: data.id,
          attributes: {
            contact_to_doc_id: user_id,
            name: data.docName,
            document_name: data.docName,
            file_ext: file_ext || 'pdf',
            file_mime_type: file_mime_type || 'application/pdf',
            filename: filename || '',
            template_type: data?.doc_type || '', //type field in Portal
            description: data?.description || '',
            filecontents: data?.fileBase64 || '',
          },
        },
      };
    } else {
      documents = {
        user: {
          id: user_id,
          access_token: token,
          token_type: token_type,
        },
        data: {
          type: 'Documents',
          id: data.id,
          attributes: {
            contact_to_doc_id: user_id,
            name: data.docName,
            document_name: data.docName,
            template_type: data?.doc_type || '', //type field in Portal
            description: data?.description || '',
          },
        },
      };
    }

    const resDocUpdate = await axios.post(
      `${config.API_BASE_URL_NODE}/api/update/document`,
      documents
    );
    return resDocUpdate;
  } catch (error) {
    return error;
  }
};

export const docLinkWithClient = async (data) => {
  try {
    const linkDocsClient = {
      data: {
        type: 'Documents',
        id: data.id,
      },
    };
    const user_id = localStorage.getItem('user_id');
    if (!user_id) return { error: 'Session Expired!  Please login again' };

    const response = await api.post(
      `/Api/V8/module/Contacts/${user_id}/relationships/documents`,
      linkDocsClient
    );
    return { response };
  } catch (err) {
    return err;
  }
};

export const getDocumentTypeList = async () => {
  try {
    const user_id = localStorage.getItem('user_id');
    const response = await axios.get(
      `${config.API_BASE_URL_NODE}/api/document/typelist/${user_id}`
    );
    const list = response?.data?.documentTypeListNotToShow;
    if (!list) return [];
    const convertedArray = Object.keys(list).map((key) => {
      return { label: list[key], value: key };
    });

    return convertedArray;
  } catch (err) {
    return err;
  }
};

function documentFilterParams() {
  try {
    return getDocumentTypeList()
      .then((res) => {
        let filters = '';
        res.map((r) => {
          filters += `&filter[operator]=and&filter[template_type][neq]=${r.value}`;
        });
        return filters;
      })
      .catch((e) => '');
  } catch (e) {
    return '';
  }
}
