import React, { useState } from 'react';
import pic1 from '../images/ankerhuis-logo.png';
import pic2 from '../images/cherrywood-logo.png';
import pic3 from '../images/villa-logo.png';
import mainImg from '../images/main-login-page.jpg';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { get } from 'mongoose';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../controllers/auth';
const images = [pic1, pic2, pic3];
const LoginPass = () => {
  const [action, setAction] = useState(true);
  const [newPassword , setNewPassword] =  useState("");
  const [newConfrimPassword , setNewConfrimPassword] =  useState("");
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [isLoading , setIsLoading] = useState(false);
  const {id} = useParams();

  const navigate = useNavigate();

  const handleUpdatePassword = async(e) => {
    e.preventDefault();
    if(!newPassword  || !newConfrimPassword) return  setAlert({ message: 'Please fill all fields', severity: 'error' });

    if (newPassword === newConfrimPassword) {
      if(!id) return navigate('/');

       try{  
         setIsLoading(true);
         setAlert({ message: "Updating password. Please wait...", severity: 'info' });
           const data = { 
            userpassword:  newPassword,
            userId: id
           }
           const  response = await resetPassword(data);
           if(response.status ===  200) {
             setAlert({ message: 'Password updated successfully', severity: 'success' });
             navigate("/");
           }else{
             setAlert({ message: 'Error updating password', severity: 'error' });
           }

          console.log("End res:", response)
       }catch(err){
        console.log(err);
        setAlert({ message: err.message, severity: 'error' });
       }finally{
        setIsLoading(false);
       }
    }else{
      setAlert({ message: 'Passwords do not match', severity: 'error' });
    }

  }
  return (
    <div id="container">
      <div className=" w-full flex h-full md:flex-row xs:flex-col">
        <div className="xs:w-full md:w-1/2 h-full flex justify-center items-center overflow-hidden relative">
          <img
            // src={images[currentIndex]}
            // alt={`Slide ${currentIndex + 1}`}
            className="w-full h-full object-cover"
            src={mainImg}
          />
          {/* <div className="absolute top-16 text-white text-4xl">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
              praesentium?
            </p>
          </div> */}
        </div>
        <div className="xs:w-full md:w-1/2">
          {
            // === not true
            <div id="login">
              <div className="md:text-3xl xs:text-xl md:font-bold xs:font-semibold ">
                <h1>Client Portal</h1>
              </div>
              <div className="flex gap-0 m-0 p-0 md:w-96 xs:w-60 bg-contain">
                <img src={pic2}></img>
                <img src={pic1} className="my-0" />
                <img src={pic3}></img>
              </div>

              {alert.message && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity={alert.severity}>{alert.message}</Alert>
                    </Stack>
                  )}
              <input
                id="email"
                name="email"
                type="password"
                required
                autoComplete="email"
                placeholder="Enter your new password"
                className="signinjs"
                onChange={(e) => setNewPassword(e.target.value)}
              ></input>
              <input
                id="password"
                name="confrimpassword"
                type="password"
                required
                autoComplete="current-password"
                placeholder="Confirm your password"
                className="signinjs"
                onChange={(e) => setNewConfrimPassword(e.target.value)}
              ></input>

              {action === true ? (
                <div className="btn1 mt-4">
                  <button onClick={handleUpdatePassword} className="signin-up-btn">
                    {isLoading ? "Updating..." : "Update" }</button>
                </div>
              ) : (
                <div className="btn1">
                  <button className="signin-up-btn">cancel</button>
                </div>
              )}

              <div>
                <div>
                  <a
                    className="footer "
                    href="#"
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Back To Login
                  </a>
                </div>
              </div>

              <>
                <div className="footer">
                  {/* <p>If you don't have an account you can</p>
                    <a onClick={handleSignUpClick}>Register here!</a> */}
                </div>
              </>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default LoginPass;
