import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { getUserDocuments } from '../controllers/documents';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from '@mui/material';
import { getMeetingToday } from '../controllers/meetings';
import { format } from 'date-fns';
import { getFormatedDateTime } from '../helpers/dt-helper';
import { lang } from '../config/lang';
const MeetingCard = () => {
  const [data, setData] = useState([]);
  const [isScheduleLoding, setIsScheduleLoding] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const todayMeetings = async () => {
    try {
      let rows = [];
      const todaysMeeting = await getMeetingToday();
      rows = todaysMeeting?.data?.data.map((doc) => {
        const { name, status, date_start, duration, date_end } = doc.attributes;
        return {
          id: doc.id,
          subject: name,
          status: status,
          dateStart: date_start,
          dateEnd: date_end,
          duration: duration,
        };
      });
      setData(rows);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsScheduleLoding(true);
    }
  };

  const timeDuration = (startDate1, EndDate) => {
    const dateStart = new Date(startDate1);
    const dateEnd = new Date(EndDate);
    const durationInMilliseconds = dateEnd - dateStart;

    const durationHours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
    const durationMinutes = Math.floor(
      (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    return durationHours + 'h: ' + durationMinutes + 'm';
  };

  useEffect(() => {
    if (!isScheduleLoding) {
      todayMeetings();
    }
  }, [page, rowsPerPage, isScheduleLoding]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatDateTime = (dateCreated) => {
    const date = new Date(dateCreated);
    const formattedDate = date.toLocaleDateString('en-GB').replace(/\//g, '-');
    const formattedTime = date.toLocaleTimeString('en-GB', { hour12: false });
    return formattedDate + ' ' + formattedTime;
  };
  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        background: '#fff',
        borderRadius: '15px',
        boxShadow: '0px 0px 25px rgba(16, 69, 249, 0.1)',
        marginBottom: '20px',
        marginTop: '32px',
        fontFamily: 'inherit',
      }}
    >
      <TableContainer>
        <div className=" md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5 ">
          <Typography
            sx={{
              textAlign: 'left  ',

              fontFamily: 'inherit',
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {lang('lblSchedule')}
          </Typography>
        </div>
        <div className="add-document-heading"></div>
        {data == '' ? (
          <div className="mb-8 ml-3 py-5 px-4 text-left text-gray-500 font-thin text-sm">
            <p>{lang('lblTodays')}</p>
          </div>
        ) : (
          <Table>
            <TableHead style={{ borderBottom: '2px solid  gray' }}>
              <TableRow>
                <TableCell>
                  <TableSortLabel style={{ fontWeight: '600' }}>
                    {lang('lblSubject')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel style={{ fontWeight: '600' }}>
                    {lang('lblStatus')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel style={{ fontWeight: '600' }}>
                    {lang('lblStartDate')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel style={{ fontWeight: '600' }}>
                    {lang('lblDuration')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow style={{ cursor: 'pointer' }}>
                    <TableCell>{row.subject}</TableCell>
                    <TableCell>
                      {row.status == 'Planned' ? (
                        <div className="td-1" id="td-priority-md">
                          <p className="td-yellow">{row.status}</p>
                        </div>
                      ) : (
                        <div className="td-1" id="td-priority-md">
                          <p className="td-Low">{row.status}</p>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {getFormatedDateTime(row.dateStart, true, '+04:00')}
                    </TableCell>
                    <TableCell>
                      {timeDuration(row.dateStart, row.dateEnd)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Paper>
  );
};

export default MeetingCard;
