import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import msgLogo1 from '../images/ankerhuis-logo.png';
import msgLogo2 from '../images/cherrywood-logo.png';
import msgLogo3 from '../images/villa-logo.png';
import { initFlowbite } from 'flowbite';
import { useRef } from 'react';
import { getCaseAllThreads, createCasesTheard } from '../controllers/cases';
const ChatComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [msgLogo, setMsgLogo] = useState();
  const [threads, setThreads] = useState([]);
  const location = useLocation();
  const row = location?.state || {};
  const getThreadsCase = () => {
    getCaseAllThreads(row.id)
      .then((response) => {
        let rows = [];
        if (response.status == 200) {
          rows = response?.data?.data.map((doc) => {
            const {
              assigned_user_id,
              assigned_user_name,
              contact_name,
              contact_id,
              date_entered,
              description,
              name,
            } = doc.attributes;
            return {
              assigned_user_id,
              assigned_user_name,
              contact_name,
              contact_id,
              date_entered,
              description,
              name,
            };
          });
          setThreads(rows);
        } else {
          alert('message sending failed..');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(true);
      });
  };

  React.useEffect(() => {
    initFlowbite();
    const treatmentFacility = localStorage.getItem('treatment_facility');
    if (treatmentFacility === 'VC') {
      setMsgLogo(msgLogo3);
    } else if (treatmentFacility === 'AH') {
      setMsgLogo(msgLogo1);
    } else if (treatmentFacility === 'CWH') {
      setMsgLogo(msgLogo2);
    } else {
      setMsgLogo();
    }
  }, []);
  React.useEffect(() => {
    if (!isLoading) {
      getThreadsCase();
    }
  }, [isLoading]);

  const handleSendMessage = async (e) => {
    if (messageInput.trim() === '') return;

    setIsSending(true);
    if (messageInput !== '' && row.id != null) {
      try {
        setIsSending(true);
        const data = {
          message: messageInput,
          caseId: row.id,
        };
        const response = await createCasesTheard(data);
        setThreads((prevThreads) => [
          ...prevThreads,
          {
            id: response.id,
            description: messageInput,
            date_entered: new Date().toISOString(),
            assigned_user_name: '',
          },
        ]);
        getThreadsCase();
      } catch (er) {
        console.error(er);
      } finally {
        setIsSending(false);
        setMessageInput('');
      }
    }
  };

  const chatEndRef = useRef(null);

  React.useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [threads]);
  const getFormattedTime = (dateCreated, timestemp = true, UTC = '') => {
    if (dateCreated === null || dateCreated === undefined) {
      return dateCreated;
    }
    const UTCZONE = UTC === '' ? 'Z' : UTC;
    let UTC_Datetime = dateCreated.split('+')[0] + UTCZONE;

    const d = new Date();
    const date = new Date(UTC_Datetime);

    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return timestemp ? formattedTime : formattedTime;
  };

  const getFormatedDateTime = (dateCreated, timestemp = true, UTC = '') => {
    if (dateCreated === null || dateCreated === undefined) {
      return dateCreated;
    }

    const UTCZONE = UTC === '' ? 'Z' : UTC;
    let UTC_Datetime = dateCreated.split('+')[0] + UTCZONE;

    const date = new Date(UTC_Datetime);

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date
      .toLocaleDateString('en-GB', options)
      .replace(/\//g, '-');

    return timestemp ? formattedDate : formattedDate;
  };
  const groupMessagesByDate = (sortedThreads) => {
    return sortedThreads.reduce((acc, thread) => {
      const date = getFormatedDateTime(thread.date_entered);

      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(thread);
      return acc;
    }, {});
  };
  const sortedThreads = threads.sort((a, b) => {
    return new Date(a.date_entered) - new Date(b.date_entered);
  });
  const groupedThreads = groupMessagesByDate(sortedThreads);
  function decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
  return (
    <div>
      <div className="container-chat bootstrap snippets bootdeys">
        <div className="col-md-7 col-xs-12 col-md-offset-2">
          <div className="flex flex-col font-medium justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            <h3 className="text-left h-auto text-xl">
              <i className="icon wb-chat-text" aria-hidden="true"></i> Case
              Updates/Threads
            </h3>
          </div>
          <div className="add-document-heading"></div>
          <div className="panel" id="chat">
            <div className="panel-body">
              <div className="chats">
                {Object.entries(groupedThreads).map(([date, messages]) => (
                  <div key={date} className="chat-date-section">
                    <div className="chat-date-header bg-slate-50">
                      <span className="font-bold ">{date}</span>
                    </div>
                    {messages.map((thread) =>
                      thread.assigned_user_name === '' ? (
                        <div className="chat chat-right" key={thread.id}>
                          <div className="chat-avatar">
                            <a
                              className="avatar avatar-online"
                              data-toggle="tooltip"
                              href="#"
                              data-placement="right"
                              title=""
                              data-original-title="June Lane"
                            >
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                alt="..."
                              />
                              <i></i>
                            </a>
                          </div>
                          <div className="chat-body">
                            <div className="flex gap-3 justify-end">
                              <div className="chat-content">
                                <p className="py-3 px-4">
                                  {decodeHtml(thread.description)}
                                </p>

                                <div className="w-full text-left text-white px-1">
                                  <time className="text-sm">
                                    {getFormattedTime(thread.date_entered)}
                                  </time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="chat chat-left" key={thread.id}>
                          <div className="chat-avatar">
                            <a
                              className="avatar avatar-online"
                              data-toggle="tooltip"
                              href="#"
                              data-placement="left"
                              title=""
                              data-original-title="Edward Fletcher"
                            >
                              <img src={msgLogo} alt="..." />
                              <i></i>
                            </a>
                          </div>
                          <div className="chat-body">
                            <div className="flex gap-3 justify-start">
                              <div className="chat-content">
                                <p className="py-3 px-4">
                                  {thread.description}
                                </p>
                                <div className="w-full text-right text-black px-1">
                                  <time className="text-sm">
                                    {getFormattedTime(thread.date_entered)}
                                  </time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ))}
                <div ref={chatEndRef} />
              </div>
            </div>
            <div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="input-group">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Say something"
                    onChange={(e) => setMessageInput(e.target.value)}
                    value={messageInput}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                  />
                  <span className="input-group-btn">
                    {messageInput !== '' ? (
                      <button
                        className="add-doc-inner-btn1 m-0"
                        type="button"
                        onClick={handleSendMessage}
                      >
                        {isSending ? 'Sending...' : 'Send'}
                      </button>
                    ) : (
                      <button disabled></button>
                    )}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
