import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config/config';
import { lang } from '../config/lang';
import backSvg from '../images/arrow-back-svgrepo-com.svg';
import msgLogo1 from '../images/ankerhuis-logo.png';
import msgLogo2 from '../images/cherrywood-logo.png';
import msgLogo3 from '../images/villa-logo.png';
import { initFlowbite } from 'flowbite';
import Threads from './Threads';
import { useRef } from 'react';
import {
  getCaseAllThreads,
  createCasesTheard,
  getCasesTypeList,
  getCasePriorityOptions,
} from '../controllers/cases';
const PreviewCase = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [msgLogo, setMsgLogo] = useState();
  const [threads, setThreads] = useState([]);
  const [caseOptions, setCaseOptions] = useState([]);
  const [priotiryOptions, setPriotiryOptions] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const row = location?.state || {};
  const getThreadsCase = () => {
    getCaseAllThreads(row.id)
      .then((response) => {
        let rows = [];
        if (response.status == 200) {
          rows = response?.data?.data.map((doc) => {
            const {
              assigned_user_id,
              assigned_user_name,
              contact_name,
              contact_id,
              date_entered,
              description,
              name,
            } = doc.attributes;
            return {
              assigned_user_id,
              assigned_user_name,
              contact_name,
              contact_id,
              date_entered,
              description,
              name,
            };
          });
          setThreads(rows);
        } else {
          alert('message sending failed..');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(true);
      });
  };
  const getPriority = async () => {
    try {
      const casePriority = await getCasePriorityOptions();
      setPriotiryOptions(casePriority);
    } catch (er) {
      console.log(er);
    }
  };
  React.useEffect(() => {
    initFlowbite();
    const treatmentFacility = localStorage.getItem('treatment_facility');
    if (treatmentFacility === 'VC') {
      setMsgLogo(msgLogo3);
    } else if (treatmentFacility === 'AH') {
      setMsgLogo(msgLogo1);
    } else if (treatmentFacility === 'CWH') {
      setMsgLogo(msgLogo2);
    } else {
      setMsgLogo();
    }
  }, []);
  React.useEffect(() => {
    if (!isLoading) {
      getThreadsCase();
      const typeList = getCasesTypeList();
      setCaseOptions(typeList);
      getPriority();
    }
  }, [isLoading]);

  const handleKeyToLabel = (key) => {
    const data = caseOptions;
    let lblType = '';
    for (let i in data) {
      if (data[i].value == key) {
        lblType = data[i].label;
        break;
      }
    }
    return lblType;
  };

  const getPriorityLabel = (key) => {
    if (!priotiryOptions) {
      return key;
    }
    for (let index in priotiryOptions) {
      if (priotiryOptions[index].value == key) {
        return priotiryOptions[index].label;
      }
    }

    return key;
  };

  const caseStatusOPtions = [
    {
      value: 'Open_New',
      label: 'New',
    },
    {
      value: 'Open_Assigned',
      label: 'Assigned',
    },
    {
      value: 'Closed_Closed',
      label: 'Closed',
    },
    {
      value: 'Open_Pending_Input',
      label: 'Pending Input',
    },
    {
      value: 'Closed_Rejected',
      label: 'Rejected',
    },
    {
      value: 'Closed_Duplicate',
      label: 'Duplicate',
    },
  ];
  const caseStatus = (key) => {
    const status = caseStatusOPtions.map((e) => {
      if (e.value == key) {
        return e.label;
      }
    });

    return status;
  };

  const handleEditcase = (data) => {
    navigate('edit', { state: { ...data } });
  };
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <form method="post">
        <div className="add-document-main-div">
          <div className="add-document-inner-div">
            <div className="flex font-medium  justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
              <h2 className=" text-left h-auto text-xl">
                {lang('lblPrevCase')}
              </h2>
              <div className="add-doc-btn">
                <div className=" px-3">
                  <button
                    className="px-3"
                    type="submit"
                    onClick={() => {
                      handleEditcase(row);
                    }}
                  >
                    {' '}
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#8D8D8D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className=" stroke-2"
                    >
                      <path d="M8.35857 1.03249H5.21127C2.62289 1.03249 1 2.86497 1 5.45924V12.4575C1 15.0518 2.61532 16.8843 5.21127 16.8843H12.6389C15.2357 16.8843 16.851 15.0518 16.851 12.4575V9.06698"></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.11461 7.87751L12.405 1.58712C13.1887 0.804293 14.4589 0.804293 15.2425 1.58712L16.2669 2.61153C17.0506 3.3952 17.0506 4.66624 16.2669 5.44906L9.94625 11.7698C9.60366 12.1123 9.13902 12.3051 8.65417 12.3051H5.50098L5.5801 9.1233C5.59189 8.65528 5.78296 8.20916 6.11461 7.87751Z"
                      ></path>
                      <path d="M11.4502 2.55901L15.2936 6.40243"></path>
                    </svg>
                  </button>
                  <button
                    className="w-5"
                    type="button"
                    onClick={() => navigate('/user/cases')}
                  >
                    {' '}
                    <img src={backSvg} className="stroke-2"></img>
                  </button>
                </div>
              </div>
            </div>
            <div className="add-document-heading"></div>

            <div className="add-document-inputs">
              <div className="document-name1">
                <label className="text-left text-sm mt-1 font-bold w-36 ">
                  {lang('lblCaseNumb')} <span className="">: </span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {row?.caseNumb || ''}
                </div>
              </div>

              <div className="document-name1 ">
                <label className="text-left text-sm mt-1 font-bold w-36">
                  {lang('lblPriority')} <span className="">: </span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {' '}
                  {getPriorityLabel(row?.priority) || ''}
                </div>
              </div>

              <div className="document-name1">
                <label className="text-left text-sm mt-1 font-bold w-36 ">
                  {lang('lblSubject')} <span className="">: </span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {row?.subject || ''}
                </div>
              </div>

              <div className="document-name1 ">
                <label
                  htmlFor="type"
                  className="text-left text-sm mt-1 font-bold w-36"
                >
                  {lang('lblQuestionCategory')}
                  <span className="">:</span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {' '}
                  {handleKeyToLabel(row?.type) || ''}
                </div>
              </div>

              <div className="document-name1 ">
                <label className="text-left text-sm mt-1 font-bold w-36">
                  {lang('lblNote')} <span className="">:</span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {row?.description || ''}
                </div>
              </div>
              
              <div className="document-name1 ">
                <label className="text-left text-sm mt-1 font-bold w-36">
                  {lang('lblStatus')} <span className="">:</span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {caseStatus(row?.Status) || ''}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </form>
      <Threads />
    </div>
  );
};

export default PreviewCase;
