import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { removeCookie } from '../controllers/cookies';
import { Cookies } from 'js-cookie';
const Logout = () => {
const navigate = useNavigate();
    useEffect (() => {
      localStorage.clear();
      navigate('/');
    })
  return (
    <div></div>
  )
}

export default Logout