
export const getFormatedDateTime = (dateCreated , timestemp = true , UTC = '' ) => {
    
    if  (dateCreated === null || dateCreated === undefined) { 
      return dateCreated;
    }
    
    const UTCZONE =  UTC === '' ? "Z" : UTC;
    let UTC_Datetime =  dateCreated.split("+")[0] + UTCZONE;

    const d = new Date();
    const date = new Date(UTC_Datetime);
    
    const formattedDate = date.toLocaleDateString('en-GB').replace(/\//g, '-');
    const formattedTime = date.toLocaleTimeString('en-GB',{hour: '2-digit', minute:'2-digit'});
    return timestemp  ?  formattedDate + ' ' + formattedTime : formattedDate ;  

  };