import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const AdminUserSidebar = () => {
  const handleTabClick = (tabName) => { };

  return (
    <div className="h-full">
      <div
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-44 h-screen overflow-y-auto transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
        // ref={sidebarRef}
      >
        <div className="h-100 px-3 py-4 bg-indigo-600 h-full">
          <Link
            to="/"
            className="flex items-center justify-center ps-2.5 mb-5 w-full overflow-x-hidden relative"
          >
            <HomeIcon className={`flex-shrink-0 w-7 h-7`} />{" "}
            <div className="absolute bottom-0 w-full left-0 right-0 border-b border-white"></div>
          </Link>
          <ul className="space-y-2 font-medium">
            <li className="text-start">
              <NavLink
                to="/AdminUserDashboard/viewUsers"
                onClick={() => handleTabClick("viewUsers")}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="viewUsers"
              >
                {/* <CgProfile
                                className={`flex-shrink-0 w-7 h-7`} /> */}
                <span className={`ms-3 text-white`}>View Users</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/AdminUserDashboard/viewMembers"
                onClick={() => handleTabClick("viewMembers")}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="viewMembers"
              >
                {/* <CgProfile
                                className={`flex-shrink-0 w-7 h-7`} /> */}
                <span className={`ms-3 text-white`}>View Members</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/AdminUserDashboard/createMeet"
                onClick={() => handleTabClick("createMeet")}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="createMeet"
              >
                {/* <CgProfile
                                className={`flex-shrink-0 w-7 h-7`} /> */}
                <span className={`ms-3 text-white`}>Create Meetings</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/AdminDashboard/resolveTickets"
                onClick={() => handleTabClick("resolveTickets")}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="resolveTickets"
              >
                {/* <CgProfile
                                className={`flex-shrink-0 w-7 h-7`} /> */}
                <span className={`ms-3 text-white`}>Resolve Tickets</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/logout"
                // onClick={() => handleTabClick("logout")}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="logout"
              >
                {/* <CgProfile
                                className={`flex-shrink-0 w-7 h-7`} /> */}
                <span className={`ms-3 text-white`}>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminUserSidebar;
