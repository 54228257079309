import React, { useState } from 'react'
import Salam from '../images/villa-logo-main.png';
import { useNavigate } from 'react-router-dom';

const Registration = () => {


    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setUserPassword(event.target.value);
    };
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };

    function isValidPassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    }


    const handleSubmit = async (event) => {
        const password = event.target.password.value;
        if (!isValidPassword(password)) {
            alert('Password does not meet security criteria.');
            event.preventDefault();
        }
        else {
            console.log(userEmail, userPassword, userName)
            event.preventDefault(); // Prevent default form submission
            try {
                const response = await fetch(`/api/client/createMember `, { // Replace with your auth endpoint
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: userEmail,
                        password: userPassword,
                        name: userName,
                    }),
                });

                if (!response.ok) {
                    throw new Error('User Creation failed');
                }

                const data = await response.json();


                console.log('User Created:', data);
                navigate('/emailConfirmation', { state: userEmail });
                // navigate('/')
                // window.alert('Registration Successful')

                // Handle successful authentication (e.g., redirect, store token)

            } catch (error) {
                console.error('Error Creating user:', error.message);
            }
        }
    };







    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm sm:mt-8 sm:my-auto">
                    <img
                        alt="Your Company"
                        src={Salam}
                        className="mx-auto h-20 w-auto"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Welcome Onboard! sign up for a new account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-sm text-start font-medium leading-6 text-gray-900">
                                Name                        </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="name"
                                    required
                                    autoComplete="name"
                                    value={userName}
                                    onChange={handleUserNameChange}
                                    className="block ps-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm text-start font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={userEmail}
                                    onChange={handleEmailChange}
                                    className="block ps-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm text-start font-medium leading-6 text-gray-900">
                                Password
                            </label>

                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={userPassword}
                                    required
                                    autoComplete="current-password"
                                    onChange={handlePasswordChange}
                                    className="block ps-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Sign up
                            </button>
                        </div>
                    </form>
                </div>
            </div>


        </>
    )
}

export default Registration