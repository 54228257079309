import { jwtDecode } from "jwt-decode";

const isTokenExpired =  () => {
    
    const token = localStorage.getItem("token");
    if(!token) return true;

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; 
    return decodedToken.exp < currentTime;

}
export default isTokenExpired;