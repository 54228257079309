import AdminDashboard from "../pages/Admin/AdminDashboard";

  

import { useLocation, Navigate } from "react-router-dom";

const ProtectedAdminDashboard = () => {
    const token = localStorage.getItem('key');
    const role = "admin" //localStorage.getItem('role');
    const location = useLocation();
  
    if (!token || role !== 'admin') {
        !token? console.log('no token'):console.log('no user no Admin')
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  
    return <AdminDashboard />;
  };
  export default ProtectedAdminDashboard

  