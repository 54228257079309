import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import MemberSidebar from './MemberSidebar';
import Header from '../../components/Header';

const MemberDashboard = () => {
  const [isSidebarToggled, setSidebarToggled] = useState(false);

  const toggleSideBar = () => {
    setSidebarToggled(!isSidebarToggled);
  };

  return (
    <div className="h-screen bg-slate-100">
      <div
        className={`fixed top-0 left-0 z-40 w-44 h-screen overflow-y-auto transition-transform ${
          isSidebarToggled ? '-translate-x-full' : 'translate-x-0'
        }`}
      >
        <MemberSidebar />{' '}
      </div>

      <div
        id="main"
        className={`flex flex-col flex-grow  w-full  ${
          isSidebarToggled ? '-translate-x-44' : 'translate-x-0'
        }`}
      >
        <Header toggleSideBar={toggleSideBar} />
        <main
          className={` overflow-x-hidden h-full overflow-y-auto p-6 pt-0 ${
            !isSidebarToggled ? '' : 'w-full'
          }`}
          style={{
            marginLeft: '176px',
            marginRight: '0px',
            marginBottom: '0px',
          }}
        >
          <div>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default MemberDashboard;
