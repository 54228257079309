import React, { useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Stack from '@mui/material/Stack';
import { createCase, getCasePriorityOptions } from '../../controllers/cases';
import { lang } from '../../config/lang';
import { getCasesTypeList } from '../../controllers/cases';
const AddCase = ({ handleGoBack }) => {
  const [subject, setSubject] = useState('');
  const [prioritySelection, setPrioritySelection] = useState(null);
  const [description, setDescription] = useState('');
  const [type, setType] = useState(null);
  const [file, setFile] = useState(null);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [isSubjectInvalid, setIsSubjectInvalid] = useState(false);
  const [isTypeInvalid, setIsTypeInvalid] = useState(false);
  const [isPriorityInvalid, setIsPriorityInvalid] = useState(false);
  const [fileBase64String, setFileBase64String] = useState('');
  const [caseOptions, setCaseOpions] = useState([]);
  const [caseOptionsLoading, setCaseOpionsLoading] = useState(false);
  const [priorityOptions, setPriorityOptions] = useState([]);
  let navigate = useNavigate();

  const subjectChange = (event) => {
    setSubject(event.target.value);
    setIsSubjectInvalid(!event.target.value.trim());
  };

  const priorityChange = (selectedOption) => {
    setPrioritySelection(selectedOption);
    setIsPriorityInvalid(!selectedOption);
  };

  const descriptionChange = (event) => setDescription(event.target.value);
  const typeChange = (selectedOption) => {
    setType(selectedOption);
    setIsTypeInvalid(!selectedOption);
  };

  const fileChange = (event) => {
    const reader = new FileReader();
    setFile(event.target.files[0]);
    reader.onloadend = () => {
      setFileBase64String(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for required fields
    const isSubjectFilled = subject.trim();
    const isTypeFilled = type;
    const isPriorityFilled = prioritySelection;

    setIsSubjectInvalid(!isSubjectFilled);
    setIsTypeInvalid(!isTypeFilled);
    setIsPriorityInvalid(!isPriorityFilled);

    if (!isSubjectFilled || !isTypeFilled || !isPriorityFilled) {
      setAlert({
        message: lang('lblAddDocAlertReq'),
        severity: 'error',
      });
      return;
    }

    // Clear alert if fields are filled
    setAlert({ message: '', severity: '' });

    const nkey = localStorage.getItem('key');
    const token = JSON.parse(nkey);

    try {
      setAlert({
        message: lang('lblSuccessAddCase'),
        severity: 'info',
      });
      const formData = {
        subject,
        prioritySelection,
        description,
        type,
        file,
        fileBase64String,
      };
      const response = await createCase(formData);

      setSubject('');
      setType(null);
      setPrioritySelection(null);
      setDescription('');
      setFile(null);
      if (response.data) {
        setAlert({
          message: lang('lblAlertAddCase'),
          severity: 'success',
        });
        navigate(-1);
      }
    } catch (error) {
      console.error('Error Filing Case:', error.message);
      setAlert({
        message: lang('lblErrorAddCase'),
        severity: 'error',
      });
    }
  };

  const getCaseOptions = () => {
    try {
      const res = getCasesTypeList();
      setCaseOpions(res);
    } catch (e) {
      console.log('error', e);
    } finally {
      setCaseOpionsLoading(true);
    }
  };
  React.useEffect(() => {
    if (!caseOptionsLoading) {
      getCaseOptions();
      const casePOptions = getCasePriorityOptions();
      setPriorityOptions(casePOptions);
    }
  }, [caseOptionsLoading]);

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div className="flex h-full flex-1 flex-col lg:py-8 md:py-8 sm:py-3  xs:mt-6 md:mt-0">
        <div className="h-full border-t-0 rounded-xl shadow-lg bg-white">
          <div className="flex flex-col font-medium justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            <h1 className=" text-left h-auto  text-xl">{lang('lblAddCase')}</h1>
            {alert.message && (
              <Stack sx={{ width: '100%', marginTop: '5px' }} spacing={2}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
              </Stack>
            )}
          </div>
          <div className="add-document-heading"></div>
          <div>
            <form className="h-full grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-rows-2 text-gray-700 md:px-5 xs:p-0.5">
              <div className="col-span-1 text-left w-full px-3 pt-4">
                <div className="py-4 w-full">
                  <label htmlFor="type" className="py-3 w-full">
                    {lang('lblQuestion')}
                  </label>
                  <br />
                  <Select
                    id="type"
                    name="type"
                    className={`border rounded-md h-10  w-full shadow-lg ${
                      isTypeInvalid ? 'border-red-500' : ''
                    }`}
                    options={caseOptions}
                    isClearable={false}
                    isSearchable={false}
                    onChange={typeChange}
                    placeholder={!caseOptionsLoading ? 'Loading...' : type}
                    value={type}
                    maxMenuHeight={150}
                  />
                </div>
                <div className="py-5 w-full">
                  <label htmlFor="subject" className="py-2 w-full">
                    {lang('lblSubject')}
                  </label>
                  <br />
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    required
                    value={subject}
                    onChange={subjectChange}
                    className={`border rounded-xl h-10  w-full shadow-lg ${
                      isSubjectInvalid ? 'border-red-500' : 'border-inherit'
                    }`}
                  />
                </div>
              </div>
              <div className="col-span-1 text-left w-full px-3 pt-4">
                <div className="py-4">
                  <label htmlFor="prioritySelection" className="py-2 w-full">
                    {lang('lblPriority')}
                  </label>
                  <br />
                  <Select
                    id="prioritySelection"
                    name="prioritySelection"
                    className={`border rounded-md h-10 w-full shadow-lg ${
                      isPriorityInvalid ? 'border-red-600' : ''
                    }`}
                    options={priorityOptions}
                    onChange={priorityChange}
                    value={prioritySelection}
                    maxMenuHeight={150}
                    isClearable={false}
                    isSearchable={false}
                  />
                </div>
                <div className="pt-4 w-full">
                  <label htmlFor="description" className="py-2 w-full">
                    {lang('lblDescription')}
                  </label>
                  <br />
                  <textarea
                    id="description"
                    required
                    value={description}
                    onChange={descriptionChange}
                    className="border border-gray-200 rounded-xl h-28 w-full shadow-lg"
                  />
                </div>
              </div>
              <div className="col-span-1 text-left w-full px-3 pb-4">
                <h1 className="font-bold text-left text-black h-auto my-4">
                  {lang('lblAttachment')}
                </h1>
                <div className="flex w-full items-center justify-start">
                  <div className="flex items-center justify-center w-full h-25">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-blue-50 hover:bg-blue-100"
                    >
                      {!file ? (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-blue-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="px-2 mb-2 text-sm text-blue-500">
                            <span className="font-semibold">
                              {lang('lblAttachmentPara')}
                            </span>{' '}
                            {lang('lblAttachmentPara2')}
                          </p>
                        </div>
                      ) : (
                        <p className="text-xs text-blue-500 px-2">
                          {file.name}
                        </p>
                      )}
                      <input
                        id="dropzone-file"
                        onChange={fileChange}
                        type="file"
                        className="hidden md:w-72 lg:w-80"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="add-doc-btn">
        <div className="add-btn1">
          <button type="submit" className="add-doc-inner-btn1">
            {lang('lblBtnSendQuestion')}
          </button>
          <button
            type="button"
            className="add-doc-inner-btn2"
            onClick={() => navigate('/cases')}
          >
            {lang('lblBtnCancel')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddCase;
