import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { constains } from '../utils/constains';
import { lang } from '../config/lang';
import backSvg from '../images/arrow-back-svgrepo-com.svg';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
const PreviewInvoices = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const row = location?.state || {};
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <form method="post">
        <div className="add-document-main-div">
          <div className="add-document-inner-div">
            <div className="flex font-medium  justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
              <h2 className=" text-left h-auto  text-xl">
                {lang('lblInvoice')}
              </h2>
              <div className="add-doc-btn">
                <div className="px-3">
                  <button
                    className="w-5"
                    type="button"
                    onClick={() => navigate('/user/invoices')}
                  >
                    {' '}
                    <img src={backSvg}></img>
                  </button>
                </div>
              </div>
            </div>
            <div className="add-document-heading"></div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label={
                        <span className="font-medium ">Invoices Detail</span>
                      }
                      medium
                      value="1"
                      sx={{
                        '&.Mui-selected': {
                          color: 'blue',
                          borderColor: 'blue',
                        },
                      }}
                    />
                    <Tab
                      label={<span className="font-medium ">Documents</span>}
                      value="2"
                      sx={{
                        '&.Mui-selected': {
                          color: 'blue',
                          borderColor: 'blue',
                        },
                      }}
                    />
                    <Tab
                      label={<span className="font-medium ">Meetings</span>}
                      value="3"
                      sx={{
                        '&.Mui-selected': {
                          color: 'blue',
                          borderColor: 'blue',
                        },
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {' '}
                  <div className="add-document-inputs">
                    <div className="document-name1">
                      <label className="text-left text-sm mt-1 font-bold w-32 ">
                        {lang('lblTitle')} <span className="">: </span>
                      </label>
                      <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                        {row?.title || ''}
                      </div>
                    </div>
                    <div className="document-name1 ">
                      <label className="text-left text-sm mt-1 font-bold w-32">
                        {lang('lblStatus')} <span className="">: </span>
                      </label>
                      <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                        {' '}
                        {row?.status || ''}
                      </div>
                    </div>
                    <div className="document-name1 ">
                      <label
                        htmlFor="type"
                        className="text-left text-sm mt-1 font-bold w-32"
                      >
                        {lang('lblDueDate')} <span className="">:</span>
                      </label>
                      <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                        {' '}
                        {row.dueDate}
                      </div>
                    </div>
                    <div className="document-name1 ">
                      <label className="text-left text-sm mt-1 font-bold w-32">
                        {lang('lblGrandTotal')} <span className="">:</span>
                      </label>
                      <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                        <NumericFormat
                          displayType="text"
                          value={row.totalAmount}
                          prefix={constains.C_Prefix}
                          decimalSeparator={constains.C_DecimalSeperator}
                          thousandSeparator={constains.C_ThousandSeperator}
                          decimalScale={constains.C_DecimalScale || 2}
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2" className="text-left">
                  No Record(s) Found.
                </TabPanel>
                <TabPanel value="3" className="text-left">
                  No Record(s) Found.
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PreviewInvoices;
