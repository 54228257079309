import api from '../api';
import axios from 'axios';
import config from '../config/config';
export const getUsernotification = async () => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const url = `/Api/V8/module/Alerts?filter[operator]=and&filter[target_module][eq]=Contacts&filter[operator]=and&page[size]=200&sort=-date_entered&filter[operator]=and&filter[url_redirect][eq]=${user_id}`;
    const response = await api.get(url);
    // const response = await api.get(`/Api/V8/module/Contacts/${user_id}/relationships/documents`);
    return response;
  } catch (error) {
    const err = error?.response?.data ? error?.response?.data : error;
    throw err;
  }
};
export const notificationIsRead = async (data) => {
  try {
    if (!data) return { data: null };
    const token = localStorage.getItem('token');
    if (!token) throw { error: 'Session Expired: please login again' };
    const token_type = localStorage.getItem('token_type') || 'Bearer';
    const body = {
      user: {
        access_token: token,
        token_type: token_type,
      },
      data: {
        data: {
          type: 'Alerts',
          id: data.id,
          attributes: {
            is_read: '1',
          },
        },
      },
    };
    const response = await axios.post(
      `${config.API_BASE_URL_NODE}/api/update/notification`,
      body
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// export const getUnreadNotificationCount = async (token, token_type) => {
//   try {
//     const body = {
//       user: {
//         access_token: token,
//         token_type: token_type,
//       },
//       data: {
//         filter: {
//           is_read: '0',
//         },
//       },
//     };

//     const response = await axios.post(
//       `${config.API_BASE_URL_NODE}/api/get/notifications`,
//       body
//     );

//     const unreadNotifications = response.data.notifications || [];

//     return unreadNotifications.length;
//   } catch (error) {
//     console.error('Error fetching unread notifications count:', error);
//     throw error;
//   }
// };
