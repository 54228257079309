import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AddCase from './AddCase';

const Ticketing = () => {

    const [userEmail, setUserEmail] = useState('');
    const [userComplaint, setUserComplaint] = useState('');
    const [userName, setUserName] = useState('');
    const [showForm, setShowForm] = useState(false);

    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
    };

    const handleComplaintChange = (event) => {
        setUserComplaint(event.target.value);
    };
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };

    useEffect(() => {

    }, [showForm])


    const handleGoBack = () => {
        setShowForm(false)
    }

    const location = useLocation();
    const data = location.state;
    console.log(data, "see please")


    useEffect(() => {
        // Initialize state with received data
        if (data) {
            setUserName(data.name || '');
            setUserEmail(data.email || '');
        }
    }, [data]);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        try {
            const response = await fetch(`/api/client/registerComplaint`, { // Replace with your auth endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: userName, email: userEmail, complaint: userComplaint, userId: data._id || "",

                }),
            });

            if (!response.ok) {
                throw new Error('Complaint registeration Failed');
            }

            const data1 = await response.json();
            console.log('Complaint Registered Successfully:', data1);
            // navigate('/userProfile', { state: data });

            // Handle successful authentication (e.g., redirect, store token)

        } catch (error) {
            console.error('Error in Complaint Filing:', error.message);
        }
    };


    return (
        <>
            {!showForm ? (<div className="flex min-h-full flex-1 flex-col  h-full py-12 ">
                <div className={`   h-full min-h-48 border-t-0 rounded-3xl shadow-lg bg-white`}>
                    <div className="flex flex-row border py-3 px-2 justify-between rounded-t-3xl shadow-md">

                        <h1 className='font-bold  text-left h-auto'>Cases</h1>
                        <button type="button" className='text-white bg-blue-600 rounded-xl p-0.5 w-14  font-semibold' onClick={() => setShowForm(true)} > Add</button>
                    </div>
                    {!showForm ? (<div>
                        table showing
                        <div className='flex flex-row'>

                            <div id="buttons_left" className=''>
                                <button type="button" className='border bg-gray-600 text-gray-800'></button>

                            </div>
                            <div id="buttons_right" className=''>

                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>

                                    </th>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>) : (

                        <div>


                        </div>

                    )}

                </div>

            </div>

            ) : (<div>
                <AddCase handleGoBack = {handleGoBack} />

            </div>
            )}




        </>
    )
}

export default Ticketing