import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { initFlowbite } from 'flowbite';
import invoiceimg from '../../images/invoice-svgrepo-com.svg';
import config from '../../config/config';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAllInvoices } from '../../controllers/invoices';
import { getFormatedDateTime } from '../../helpers/dt-helper';
import { NumericFormat } from 'react-number-format';
import { constains } from '../../utils/constains';
import { lang } from '../../config/lang';
import { Paid } from '@mui/icons-material';
const MUITableComponent = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [isAnySelected, setIsAnySelected] = useState(false);
  const [download, setDownload] = useState();
  const [invoiceId, setInvoiceId] = useState(null);
  const [templateId, setTemplateId] = useState('');
  const navigate = useNavigate();
  const handlePrevMeetings = (data) => {
    navigate('preview', { state: { ...data } });
  };
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map((row) => {
      return Object.values(row)
        .map((value) => {
          if (value instanceof Date) {
            return getFormatedDateTime(value, false, '+04:00');
          }
          return value;
        })
        .join(',');
    });

    return [headers, ...rows].join('\n');
  };
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateEntered', 'dueDate');
  const fetchData = async () => {
    try {
      setIsLoading(true);
      let rows = [];
      const invs = await getAllInvoices();
      rows = invs?.data?.data.map((inv) => {
        const { name, due_date, status, date_entered, total_amount } =
          inv.attributes;
        const displayStatus = status == 'Paid' ? 'Paid' : 'Not Paid';
        const dueDateString = due_date
          ? getFormatedDateTime(due_date, false, '')
          : '';
        const dueDate = parseDateFromString(dueDateString);
        const isOverdue = dueDate < new Date();
        const isPaid = displayStatus === 'Paid';
        return {
          id: inv.id,
          title: name,
          dueDate: due_date ? getFormatedDateTime(due_date, false, '') : '',
          status: displayStatus,
          dateEntered: getFormatedDateTime(date_entered, false, '+04:00'),
          totalAmount: total_amount,
          overdueStatus: !due_date ? (
            <div></div>
          ) : isPaid ? (
            <div></div>
          ) : isOverdue ? (
            <span
              style={{
                backgroundColor: '#ffe2e2',
                padding: '5px',
                borderRadius: '15px',
                border: '1px solid #c61f1f',
                color: '#c61f1f',
                lineHeight: '25px',
                fontSize: '11px',
              }}
            >
              Overdue
            </span>
          ) : (
            <div></div>
          ),
        };
      });
      setData(rows);
      setTotalCount(invs?.data?.data.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);
  // Handle sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const parseDateFromString = (dateString) => {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      return new Date(`${year}-${month}-${day}`);
    }
    return null;
  };
  // Sort the data based on the order and orderBy state
  const sortedData = data.sort((a, b) => {
    if (orderBy === 'title' || orderBy === 'status') {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === 'dateEntered') {
      const dateA = parseDateFromString(a.dateEntered);
      const dateB = parseDateFromString(b.dateEntered);
      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return order === 'asc' ? 1 : -1;
      if (dateB === null) return order === 'asc' ? -1 : 1;
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    } else if (orderBy === 'totalAmount') {
    } else if (orderBy === 'dueDate') {
      const dateA = parseDateFromString(a.dueDate);
      const dateB = parseDateFromString(b.dueDate);
      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return order === 'asc' ? 1 : -1;
      if (dateB === null) return order === 'asc' ? -1 : 1;
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    } else if (orderBy === 'totalAmount') {
      return order === 'asc'
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    } else {
      return 0;
    }
  });
  // Handle change of page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Handle change of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    setDownload(row.id);
    const currentIndex = selected.indexOf(row.id);
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(row.id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    setIsAnySelected(newSelected.length > 0);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((data) => data.id);
      setSelected(newSelected);
      setIsAnySelected(true);
    } else {
      setSelected([]);
      setIsAnySelected(false);
    }
    setAllSelected(event.target.checked);
    return false;
  };
  const buttonClassName = isAnySelected
    ? 'text-sm px-2 text-black'
    : 'text-sm px-2 text-gray-400';

  const svgStrokeColor = isAnySelected ? '#000000' : '#8D8D8D';

  const downloadCSV = () => {
    if (selected.length === 0) {
      return;
    }
    const downloadData = data.filter((row) => selected.includes(row.id));
    const csvData = new Blob([convertToCSV(downloadData)], {
      type: 'text/csv',
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `Invoice.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    initFlowbite();
    const treatmentFacility = localStorage.getItem('treatment_facility');
    switch (treatmentFacility) {
      case 'VC':
        setTemplateId('c1aa55f3-5735-a91c-07e4-66f23fc39b8e');
        break;
      case 'AH':
        setTemplateId('568302fc-1364-5397-1728-672b36028028');
        break;
      case 'CWH':
        setTemplateId('d7cb051e-9944-bdb5-b2e3-672b38ea590b');
        break;
      default:
        setTemplateId('');
    }
  }, []);

  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        background: '#fff',
        borderRadius: '15px',
        boxShadow: '0px 0px 25px rgba(16, 69, 249, 0.1)',
        fontFamily: 'inherit',
        marginBottom: '20px',
        marginTop: '32px',
      }}
    >
      <TableContainer>
        <div className=" md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5 ">
          <Typography
            sx={{
              flex: '1 1 100%',
              textAlign: 'left  ',
              fontFamily: 'inherit',
              fontWeight: '500',
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {lang('lblInvoices')}
          </Typography>
        </div>
        <div className="add-document-heading"></div>
        <div className="flex gap-1 mt-5 ml-4">
        {/* <div className="bg-slate-100 rounded-md mr-2 ml-4 px-2 py-1 flex">
            {' '}
           <button className={buttonClassName} onClick={downloadCSV}>
              {lang('lblExport')}
            </button>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke={svgStrokeColor}
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M4.4947 6.14103H3.78865C2.24865 6.14103 1 7.38968 1 8.92968L1 12.6189C1 14.1581 2.24865 15.4068 3.78865 15.4068H12.2114C13.7514 15.4068 15 14.1581 15 12.6189V8.92211C15 7.38665 13.7551 6.14103 12.2197 6.14103H11.5061"></path>
              <path d="M8 0.999998V10.1121"></path>
              <path d="M5.79395 3.21601L7.99989 1.00023L10.2066 3.21601"></path>
            </svg>
          </div>*/}
        </div>
        <div className="p-5">
          <Table>
            <TableHead style={{ borderBottom: '2px solid  gray' }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={allSelected}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'dateEntered'}
                    direction={orderBy === 'dateEntered' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'dateEntered')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblInvoiceDate')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'title'}
                    direction={orderBy === 'title' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'title')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblTitle')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'totalAmount'}
                    direction={orderBy === 'totalAmount' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'totalAmount')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblGrandTotal')}
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'status')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblStatus')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'dueDate'}
                    direction={orderBy === 'dueDate' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'dueDate')}
                    style={{ fontWeight: '600' }}
                  >
                    {lang('lblDueDate')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  // active={orderBy === 'dueDate'}
                  // direction={orderBy === 'dueDate' ? order : 'asc'}
                  // onClick={(event) => handleRequestSort(event, 'dueDate')}
                  style={{ fontWeight: '600' }}
                >
                  <div></div>
                  {/* column for the overdue */}
                </TableCell>

                <TableCell style={{ fontWeight: '600' }}>
                  {lang('lblAction')}
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <div
                style={{
                  padding: '20px',
                  margin: '20px',
                  position: 'absolute',
                  left: '40%',
                }}
              >
                <CircularProgress /> {/* Loader while data is being fetched */}
              </div>
            ) : (
              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id} hover style={{ cursor: 'pointer' }}>
                      <TableCell
                        component="th"
                        id={row.id}
                        scope="row"
                        padding="checkbox"
                      >
                        <Checkbox
                          color="primary"
                          checked={selected.indexOf(row.id) !== -1}
                          onChange={() => handleRowClick(row)}
                          inputProps={{
                            'aria-labelledby': row.id,
                          }}
                        />
                      </TableCell>
                      <TableCell> {row.dateEntered} </TableCell>
                      <TableCell
                        onClick={() => {
                          const invoiceId = row.id;
                          const url = `${config.PREVIEW_INVOICE_LINK}uid=${invoiceId}&templateID=${templateId}`;
                          window.location.href = url;
                        }}
                        className="hover:text-blue"
                      >
                        {row.title || '-'}
                      </TableCell>
                      <TableCell>
                        <NumericFormat
                          displayType="text"
                          value={row.totalAmount}
                          prefix={constains.C_Prefix}
                          decimalSeparator={constains.C_DecimalSeperator}
                          thousandSeparator={constains.C_ThousandSeperator}
                          decimalScale={constains.C_DecimalScale || 2}
                        />
                      </TableCell>
                      <TableCell> {row.status || '-'} </TableCell>

                      <TableCell> {row.dueDate}</TableCell>
                      <TableCell> {row.overdueStatus}</TableCell>
                      <TableCell>
                        <div className="flex gap-5">
                          <svg
                            width="22"
                            height="18"
                            viewBox="0 0 22 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#8D8D8D"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="hover:stroke-blue-600 stroke-2"
                            // onClick={() => handlePrevMeetings(row)}
                            onClick={() => {
                              const invoiceId = row.id;
                              const url = `${config.PREVIEW_INVOICE_LINK}uid=${invoiceId}&templateID=${templateId}`;
                              window.open(url, '_blank');
                            }}
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.4177 8.89427C14.4177 10.7818 12.8869 12.3116 10.9993 12.3116C9.11176 12.3116 7.58203 10.7818 7.58203 8.89427C7.58203 7.00562 9.11176 5.47589 10.9993 5.47589C12.8869 5.47589 14.4177 7.00562 14.4177 8.89427Z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.9978 16.7881C15.1146 16.7881 18.88 13.8281 21 8.89405C18.88 3.96 15.1146 1 10.9978 1H11.0022C6.8854 1 3.12 3.96 1 8.89405C3.12 13.8281 6.8854 16.7881 11.0022 16.7881H10.9978Z"
                            ></path>
                            <title>{lang('lblViewInvoice')}</title>
                          </svg>
                          <a
                            href={`${config.PREVIEW_INVOICE_LINK_DOWNLOAD}uid=${row.id}&templateID=${templateId}`} // Use dynamic URL from config
                            download={`Invoice_${row.id}.pdf`}
                            onClick={(e) => {
                              e.preventDefault();
                              const url = e.currentTarget.href;
                              const a = document.createElement('a');
                              a.href = url;
                              a.download = `Invoice_${row.id}.pdf`;
                              document.body.appendChild(a);
                              a.click();
                              document.body.removeChild(a);
                            }}
                          >
                            <svg
                              width="22"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#8D8D8D"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="hover:stroke-blue-600 stroke-2"
                            >
                              <path d="M10.1021 12.8634L10.1021 2.82922"></path>
                              <path d="M12.5322 10.4236L10.1022 12.8636L7.67223 10.4236"></path>
                              <path
                                opacity="0.7"
                                d="M13.9626 6.77332H14.7401C16.4359 6.77332 17.8101 8.14748 17.8101 9.84415V13.9141C17.8101 15.6058 16.4392 16.9766 14.7476 16.9766H5.46423C3.76839 16.9766 2.39339 15.6016 2.39339 13.9058L2.39339 9.83498C2.39339 8.14415 3.76506 6.77332 5.45589 6.77332L6.24089 6.77332"
                              ></path>
                              <title>{lang('lblDownloadInvs')}</title>
                            </svg>
                          </a>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </div>
      </TableContainer>
      {/* Pagination Controls */}
      {!isLoading && (
        <TablePagination
          component="div"
          count={totalCount} // The total number of rows (e.g., from API)
          page={page} // Current page
          onPageChange={handleChangePage} // Function to handle page change
          rowsPerPage={rowsPerPage} // Number of rows per page
          onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changing rows per page
          rowsPerPageOptions={[10, 25, 50, 100]} // The options for number of rows per page
          labelRowsPerPage={lang('lblFooterRowPage')}
        />
      )}
    </Paper>
  );
};
export default MUITableComponent;
