import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { updateUserPassword } from '../controllers/auth';
const ChangePass = () => {
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newConfirmPassword, setNewConfirmPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const authPassword = localStorage.getItem('auth_password') || '';
    const authUsername = localStorage.getItem('auth_username') || '';

    if (oldPassword !== authPassword)
      return setAlert({ message: 'Invalid old pasword', severity: 'error' });
    if (newPassword !== newConfirmPassword)
      return setAlert({
        message: 'New password and confirm password do not match',
        severity: 'error',
      });
    if (!oldPassword || !newPassword || !newConfirmPassword)
      return setAlert({ message: 'Please fill all fields', severity: 'error' });

    try {
      setAlert({
        message: 'Password updating please wait...',
        severity: 'info',
      });
      const result = await updateUserPassword({
        username: authUsername,
        userpassword: newPassword,
      });
      if (result.status === 200) {
        localStorage.setItem('auth_password', newPassword);
        setAlert({
          message: 'Password updated successfully',
          severity: 'success',
        });
      } else {
        setAlert({
          message: `Error! ${result.statusText} `,
          severity: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      setAlert({ message: 'Password update failed', severity: 'error' });
    } finally {
      setLoading(false);
      setAlert({
        message: 'Password updated successfully',
        severity: 'success',
      });
    }
  };

  return (
    <>
      <div className="main-box">
        <div className="user-pannel">
          <div className="user-pannel-info">
            <div className="user-profile-col1">
              <form method="post" onSubmit={handleChangePassword}>
                <div className="change-password">
                  <h1 className="add-document-heading1 text-left">
                    {alert.message && (
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity={alert.severity}>{alert.message}</Alert>
                      </Stack>
                    )}
                    Change Password
                  </h1>
                  <h4>
                    Current password <span className="staric">*</span>
                  </h4>
                  <input
                    name="oldPassword"
                    type="password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    value={oldPassword || ''}
                  ></input>
                  <h4>
                    New password <span className="staric">*</span>
                  </h4>
                  <input
                    name="newPassword"
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword || ''}
                  ></input>
                  <h4>
                    Confirm password <span className="staric">*</span>
                  </h4>
                  <input
                    name="newConfirmPassword"
                    type="password"
                    onChange={(e) => setNewConfirmPassword(e.target.value)}
                    value={newConfirmPassword || ''}
                  ></input>
                  <div className="left-btn">
                    <button
                      // onClick={handlePasswordUpdate}
                      className="user-profile-btn1"
                      type="submit"
                      // disabled={loading ? true : false }
                    >
                      {loading ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePass;
