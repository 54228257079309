import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(I18nextBrowserLanguageDetector)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    supportedLngs: ['en', 'fr', 'de', 'nl'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: { Documents: 'Documents' },
      },
      fr: {
        translation: { Documents: 'Ducamnt' },
      },
    },
  });
i18n.use(initReactI18next).init({
  lng: 'fr',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
