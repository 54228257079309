import React from 'react'
import { useLocation } from 'react-router-dom'

const ViewMeetings = () => {
    const location = useLocation();
    //const data = location.state;
    const data = [

       { id: 0,
        title: 'Orientation',
        agenda: 'Get to know',
        date: '2023-02-15',
        time: '10:00 AM',}
]
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col  px-6 py-12 lg:px-8">

                <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                    <div className="overflow-x-auto max-h-96 shadow-md sm:rounded-lg">
                        <table className="w-full text-sm  rtl:text-right  text-center bg-white">
                            <thead className="text-xs text-black uppercase bg-gray-50">
                                <th>
                                    Sr.
                                </th>
                                <th>
                                    Title
                                </th>
                                <th>
                                    Agenda
                                </th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {data?.map((meeting, index) => (
                                    <tr key={index} className="bg-white border-b  hover:bg-gray-50 ">
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>{meeting.title}</td>
                                        <td>{meeting.agenda}</td>
                                        <td>{meeting.date}</td>
                                        <td>{meeting.time}</td>
                                        <td>Action</td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewMeetings