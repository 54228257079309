import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createDocument,
  getDocumentsWithLimit,
} from '../controllers/documents';
import { useRef } from 'react';
import Select from 'react-select';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { lang } from '../config/lang';
import { getDocumentTypeList } from '../controllers/documents';
import {
  getDocuemtnTypeLabelByKey,
  getTypeLabelByKey,
} from '../helpers/general-helper';
const AddDocument = () => {
  let navigate = useNavigate();
  const [error, setError] = useState('');
  const [resMessage, setResMessage] = useState('');
  const [docName, setDocName] = useState('');
  const [docNameError, setDocNameError] = useState(false);
  const [publishDate, setPublishDate] = useState('');
  const [publishDateError, setPublishDateError] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [base64String, setBase64String] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const location = useLocation();
  const doc = location.state || {};
  const [type, setType] = useState(getTypeLabelByKey(doc.type) || '');
  const [typeError, setTypeError] = useState(false);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [docTypeOpLoading, setDocTypeOpLoading] = useState(false);
  const [typeKeyToLabel, settypeKeyToLabel] = useState([]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const maxSize = 15 * 1024 * 1024;
      if (selectedFile.size > maxSize) {
        setError('File size should not exceed 15MB.');
        setFileSelected(false);
        event.target.value = '';
      } else {
        setError('');
        setFileSelected(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setBase64String(base64String.split(',')[1]);
        };
        reader.readAsDataURL(selectedFile);
      }
    } else {
      setFileSelected(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'docName') {
      setDocName(value);
      setDocNameError(!value);
    }
    // else if (name === 'publishDate') {
    //   setPublishDate(value);
    //   setPublishDateError(!value);
    // }
    else if (name === 'description') {
      setDescription(value);
      setDescriptionError(!value);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let valid = true;
    if (!docName) {
      setDocNameError(true);
      valid = false;
    }
    // if (!publishDate) {
    //   setPublishDateError(true);
    //   valid = false;
    // }
    // if (!description) {
    //   setDescriptionError(true);
    //   valid = false;
    // }
    if (!fileSelected) {
      setFileError(true);
      valid = false;
    }
    if (!type) {
      // Validate type selection
      setTypeError(true);
      valid = false;
    }

    if (valid) {
      const data = {
        docName: docName || '',
        // publishDate: publishDate || '',
        // expireDate: expirationDate || '',
        description: description || '',
        doc_type: type.value || '',
        file: fileSelected || '',
        fileBase64: base64String || '',
      };
      setLoading(true);
      try {
        const res = await createDocument(data);
        if (res.status === 201) {
          setAlert({
            message: lang('lblAddDocAlertSucc'),
            severity: 'success',
          });
          navigate(-1, {
            state: {
              message: lang('lblAddDocAlertSucc'),
              severity: 'success',
            },
          });
        } else {
          setAlert({ message: lang('lblAddDocAlertError'), severity: 'error' });
        }
      } catch (err) {
        setAlert({
          message: err || err?.message || lang('lblAddDocAlertError'),
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setAlert({
        message: lang('lblAddDocAlertReq'),
        severity: 'error',
      });
    }
  };

  // const inputRef = useRef(null);
  // const expirationDateRef = useRef(null);
  // const handleInputClick = () => {
  //   if (inputRef.current) {
  //     inputRef.current.showPicker();
  //   }
  // };
  // const handleExpirationDateClick = () => {
  //   if (expirationDateRef.current) {
  //     expirationDateRef.current.showPicker();
  //   }
  // };
  var options = [];

  const getDocType = () => {
    try {
      const documentType = getDocuemtnTypeLabelByKey();
      settypeKeyToLabel(documentType);
    } catch (error) {
    } finally {
      setDocTypeOpLoading(true);
    }
  };
  React.useEffect(() => {
    if (!docTypeOpLoading) {
      getDocType();
    }
  }, [docTypeOpLoading]);
  // Set selected option based on the type value
  const selectedOption = docTypeOptions.find((option) => option.value === type);

  const typeChange = (selectedOption) => {
    setType(selectedOption); // Store the value directly
    setTypeError(!selectedOption);
  };
  // console.log(type);

  return (
    <form onSubmit={handleSave} method="post">
      <div className="add-document-main-div">
        <div className="add-document-inner-div">
          <div className="flex flex-col  justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            {alert.message && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
              </Stack>
            )}
            <h2 className="text-left h-auto text-xl font-medium">
              {lang('lblUploadDoc')}
            </h2>
          </div>
          <div className="add-document-heading"></div>
          <div className="add-document-inputs">
            <div className="document-name">
              <label className="add-doc-label">
                {lang('lblDocumentName')}{' '}
                <span className="add-doc-span">*</span>
              </label>
              <input
                type="text"
                name="docName"
                className="add-doc-name-input"
                value={docName}
                onChange={handleChange}
                style={{ borderColor: docNameError ? 'red' : '' }}
              />
              {docNameError && (
                <p style={{ color: 'red', textAlign: 'left' }}></p>
              )}
            </div>
            <div className="document-name">
              <label className="add-doc-label">
                {lang('lblUploadFile')} <span className="add-doc-span">*</span>
              </label>
              <input
                type="file"
                className="add-file-input"
                onChange={handleFileChange}
                style={{ borderColor: fileError ? 'red' : '' }}
              />
              {error && (
                <p
                  style={{
                    borderColor: 'red',
                    color: 'red',
                    textAlign: 'left',
                  }}
                >
                  {error}
                </p>
              )}
              {fileError && <p style={{ color: 'red', textAlign: 'left' }}></p>}
              <p className="add-doc-p">{lang('lblMaxFileSize')}</p>
            </div>
            {/* <div className="document-name" onClick={handleInputClick}>
              <label className="add-doc-label">
                Publish Date <span className="add-doc-span">*</span>
              </label>
              <input
                ref={inputRef}
                type="date"
                name="publishDate"
                className="add-doc-name-input"
                value={publishDate}
                onChange={handleChange}
                style={{ borderColor: publishDateError ? 'red' : '' }}
              />
            </div>
            <div className="document-name" onClick={handleExpirationDateClick}>
              <label className="add-doc-label">Expiration Date</label>
              <input
                ref={expirationDateRef}
                type="date"
                name="expirationDate"
                className="add-doc-name-input"
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
              />
            </div> */}
            <div className="document-name">
              <label htmlFor="type" className="text-left text-sm mb-2 ">
                {lang('lblType')} <span className="add-doc-span">*</span>
              </label>
              <Select
                id="type"
                name="type"
                placeholder={!docTypeOpLoading ? 'Loading...' : doc.type}
                className={`add-doc-name-input1 ${
                  typeError ? 'border-red-500' : ''
                }`}
                options={typeKeyToLabel}
                onChange={typeChange}
                value={type}
                maxMenuHeight={150}
                isClearable={false}
                isSearchable={false}
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'black',
                    padding: '0px',
                    textAlign: 'left',
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '',
                    border: typeError ? '1px solid red' : 'none',
                    height: 'auto',
                    textAlign: 'left',
                  }),
                  menu: (provided) => ({
                    ...provided,
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: 'black',
                    textAlign: 'left',
                  }),
                }}
              />
            </div>
            <div className="document-name">
              <label className="add-doc-label">{lang('lblNote')}</label>
              <textarea
                name="description"
                className="add-doc-textarea"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="add-doc-btn">
        <div className="add-btn">
          <button className="add-doc-inner-btn1" type="submit">
            {loading ? lang('lblBtnSaving') : lang('lblBtnSave')}
          </button>
          <button
            className="add-doc-inner-btn2"
            type="button"
            onClick={() => navigate(-1)}
          >
            {lang('lblBtnCancel')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddDocument;
