import React, { useState } from 'react';
import HI from '../images/hi.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import axios from 'axios';
// import useAuth from "../contexts/AuthContext";

const Login = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  //const { login } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setUserPassword(event.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate username and password

    if (userEmail && userPassword) {
      const body = {
        email: userEmail,
        password: userPassword,
      };
      console.log('Hello signing in ');
      await axios
        .post('/api/client/login', body)
        .then((res) => {
          if (res.data.token) {
            console.log('i am in General Users response');
            // setUser(res.data.data)
            localStorage.setItem('key', JSON.stringify(res.data.token));
            localStorage.setItem('user', JSON.stringify(res.data.user));

            if (res.data.user.role === 'member') {
              navigate('/MemberDashboard/memberHomepage');
            } else if (res.data.user.role === 'user') {
              navigate('/AdminUserDashboard');
            } else if (res.data.user.role === 'admin') {
              navigate('/AdminDashboard');
            } else {
              navigate('/');
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            // The request was made and the server responded with a status code
            const { status, data } = err.response;
            if (status === 401 && data.message === 'User not found') {
              alert('Incorrect email.');
            } else if (status === 401 && data.message === 'Invalid password') {
              alert('Incorrect password.');
            } else {
              alert('Both email and password are incorrect.');
            }
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error', err.message);
          }
        })
        .finally();
    } else {
      alert('Email and password are required!');
    }
  };

  //Not Being used!!!
  //Not Being used!!!
  //Not Being used!!!
  //Not Being used!!!

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    console.log(userEmail, userPassword);

    try {
      const response = await fetch(`/api/client/getusers`, {
        // Replace with your auth endpoint
        method: 'POST',
        headers: {
          // 'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          password: userPassword,
        }),
      });

      if (!response.ok) {
        throw new Error('Authentication failed');
      }

      const data = await response.json();
      console.log('Authentication successfull:', data);
      //Remeber this is how you'll share data among components.
      navigate('/userProfile', { state: data });

      // Handle successful authentication (e.g., redirect, store token)
    } catch (error) {
      console.error('Error authenticating user:', error.message);
    }
  };

  const handleSignUpClick = () => {
    navigate('/register'); // Navigate to the registration route
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm sm:my-auto">
          <img alt="Your Company" src={HI} className="mx-auto h-20 w-auto" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm  text-start font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={userEmail}
                  onChange={handleEmailChange}
                  className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-start leading-6 text-gray-900"
              >
                Password
              </label>

              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={userPassword}
                  required
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  className="block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Don't have an account?{' '}
            <button
              onClick={handleSignUpClick} // Use the navigation function
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign up
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
