import React from 'react'

const viewUsers = () => {
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div>
                    <table className="min-w-full bg-transparent">
                      <thead className='items-stretch'>
                        <tr>
                          <th className="py-2 px-4  border-gray-300 text-left leading-tight">Sr.</th>
                          <th className="py-2 px-4  border-gray-300 text-left leading-tight">Name</th>
                          <th className="py-2 px-4  border-gray-300 text-left leading-tight">Contact</th>
                          <th className="py-2 px-4  border-gray-300 text-left leading-tight">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {groupMembers.map((member, index) => (
                          <tr key={index}>
                            <td className="py-2 px-4 border-b border-gray-300">{member.name}</td>
                            <td className="py-2 px-4 border-b border-gray-300">{member.registrationNumber}</td>
                            <td className="py-2 px-4 border-b border-gray-300">
                              <button
                                className="text-red-600 hover:text-red-800 focus:outline-none"
                                onClick={() => handleDeleteMember(member._id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))} */}
                      </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </>)
}

export default viewUsers