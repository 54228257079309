import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { lang } from '../../config/lang';
const AdminSidebar = ({ toggleSideBar }) => {
  const location = useLocation();
  const handleTabClick = (tabName) => {};
  return (
    <div className="h-full ">
      <div
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-60 h-screen overflow-y-auto transition-transform -translate-x-0 sm:translate-x-0"
        aria-label="Sidebar"
        // ref={sidebarRef}
      >
        <div className="px-3 py-1 bg-white h-full ">
          <li className="tect-start list-none xs:pl-3 md:pl-6  mb-2  font-light">
            <NavLink
              to="dashboard"
              className={({ isActive }) =>
                `relative flex items-center px-2 py-2 rounded-lg stroke-slate-900 hover:bg-sky-100 dark:bg-sky-300 hover:text-blue-700 ${
                  isActive
                    ? 'bg-sky-100 dark:bg-sky-300  text-blue-700 stroke-blue-600 after-sel'
                    : ' '
                }`
              }
              onClick={() => {
                handleTabClick('dashboard');
                if (window.innerWidth < 768) toggleSideBar();
              }}
              // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              data-tab="dashboard"
            >
              {' '}
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                // stroke="#333333"
                stroke-width="1.5px"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="hover:stroke-blue-700 stroke-2"
              >
                <g opacity="0.4">
                  <path
                    className=""
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 3.52778C1 1.63179 1.0203 1 3.52778 1C6.03525 1 6.05556 1.63179 6.05556 3.52778C6.05556 5.42377 6.06355 6.05556 3.52778 6.05556C0.992003 6.05556 1 5.42377 1 3.52778Z"
                  ></path>
                  <path
                    opacity="0.7"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.94434 3.52778C8.94434 1.63179 8.96464 1 11.4721 1C13.9796 1 13.9999 1.63179 13.9999 3.52778C13.9999 5.42377 14.0079 6.05556 11.4721 6.05556C8.93634 6.05556 8.94434 5.42377 8.94434 3.52778Z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 11.4722C1 9.57623 1.0203 8.94444 3.52778 8.94444C6.03525 8.94444 6.05556 9.57623 6.05556 11.4722C6.05556 13.3682 6.06355 14 3.52778 14C0.992003 14 1 13.3682 1 11.4722Z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.94434 11.4722C8.94434 9.57623 8.96464 8.94444 11.4721 8.94444C13.9796 8.94444 13.9999 9.57623 13.9999 11.4722C13.9999 13.3682 14.0079 14 11.4721 14C8.93634 14 8.94434 13.3682 8.94434 11.4722Z"
                  ></path>
                </g>
              </svg>
              <span className={`ms-3  text-sm hover:text-blue-700 font-light `}>
                {lang('lblDashboard')}
              </span>
            </NavLink>
          </li>
          {/* <Link
              to="/"
              className="flex items-center justify-center ps-2.5 mb-5 w-full overflow-x-hidden relative"
            >
              <div className="absolute bottom-0 w-full left-0 right-0 border-b border-white"></div>
            </Link> */}
          <hr className="mb-4" />
          <ul className="  text-black font-light">
            <li className="tect-start xs:pl-3 md:pl-6 ">
              <NavLink
                to="documents"
                onClick={() => {
                  handleTabClick('documents');
                  if (window.innerWidth < 768) toggleSideBar();
                }}
                className={({ isActive }) =>
                  `stroke-slate-900 relative flex items-center px-2 py-2 rounded-lg text-black hover:bg-sky-100 dark:bg-sky-300 hover:text-blue-800 ${
                    isActive
                      ? ' bg-sky-100 dark:bg-sky-300 text-blue-800 stroke-blue-600 after-sel'
                      : ''
                  }`
                }
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="documents"
              >
                {' '}
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // stroke="#333333"
                  stroke-width="1.5px"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="hover:stroke-blue-700 stroke-2"
                >
                  <g opacity="0.4">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.4807 10.2411C14.4807 12.7575 12.9979 14.2404 10.4815 14.2404H5.00571C2.48288 14.2404 1 12.7575 1 10.2411V4.75891C1 2.24251 1.92439 0.759628 4.4408 0.759628H5.84665C6.35179 0.759628 6.82744 0.997458 7.13053 1.40157L7.77247 2.25535C8.07621 2.65867 8.55145 2.89628 9.05635 2.89729H11.0464C13.5692 2.89729 14.5 4.18117 14.5 6.74893L14.4807 10.2411Z"
                    ></path>
                    <path d="M4.36328 9.34874H11.1101"></path>
                  </g>
                </svg>
                <span
                  className={`ms-3 text-sm hover:text-blue-700 font-light `}
                >
                  {lang('lblDocuments')}
                </span>
              </NavLink>
            </li>
            <li className="text-start xs:pl-3 md:pl-6 ">
              <NavLink
                to="meetings"
                onClick={() => {
                  handleTabClick('meetings');
                  if (window.innerWidth < 768) toggleSideBar();
                }}
                className={({ isActive }) =>
                  `stroke-slate-900 relative flex items-center px-2 py-2 rounded-lg text-black hover:bg-sky-100 dark:bg-sky-300 hover:text-blue-700 ${
                    isActive
                      ? 'bg-sky-100 dark:bg-sky-300 text-blue-700 stroke-blue-600 after-sel'
                      : ''
                  }`
                } // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="meetings"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // stroke="#333333"
                  stroke-width="1.5px"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="hover:stroke-blue-700 stroke-2"
                >
                  <g opacity="0.4">
                    <path d="M1.04102 11.2319C1.04102 11.2319 1.1485 12.5475 1.17348 12.9623C1.20679 13.5187 1.42177 14.1402 1.78057 14.5716C2.28697 15.1832 2.88346 15.399 3.67978 15.4005C4.61614 15.402 11.425 15.402 12.3614 15.4005C13.1577 15.399 13.7542 15.1832 14.2606 14.5716C14.6194 14.1402 14.8343 13.5187 14.8684 12.9623C14.8926 12.5475 15.0001 11.2319 15.0001 11.2319"></path>
                    <path d="M5.34961 3.55062V3.26979C5.34961 2.3463 6.09749 1.59842 7.02098 1.59842H8.97545C9.89818 1.59842 10.6468 2.3463 10.6468 3.26979L10.6476 3.55062"></path>
                    <path d="M7.99805 12.1412V11.1617"></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 5.86659V8.49097C2.45185 9.44852 4.19134 10.1192 6.10039 10.3849C6.329 9.55147 7.08066 8.9406 7.99431 8.9406C8.89358 8.9406 9.66038 9.55147 9.87384 10.3925C11.7905 10.1268 13.5368 9.45609 14.9962 8.49097V5.86659C14.9962 4.5843 13.9644 3.5518 12.6822 3.5518H3.3216C2.03931 3.5518 1 4.5843 1 5.86659Z"
                    ></path>
                  </g>
                </svg>
                <span
                  className={`ms-3  text-sm   hover:text-blue-700 font-light `}
                >
                  {lang('lblMeetings')}
                </span>
              </NavLink>
            </li>
            <li className="tect-start xs:pl-3 md:pl-6 ">
              <NavLink
                to="invoices"
                className={({ isActive }) =>
                  `stroke-slate-900 relative flex items-center px-2 py-2 rounded-lg text-black hover:bg-sky-100 dark:bg-sky-300 hover:text-blue-700 ${
                    isActive
                      ? 'bg-sky-100 dark:bg-sky-300 text-blue-700 stroke-blue-600 after-sel'
                      : ''
                  }`
                }
                onClick={() => {
                  handleTabClick('invoices');
                  if (window.innerWidth < 768) toggleSideBar();
                }}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="invoices"
              >
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // stroke="#333333"
                  stroke-width="1.5px"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="hover:stroke-blue-700 stroke-2"
                >
                  <g opacity="0.4">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.7653 0.834229H4.20486C2.4829 0.834229 1 2.22935 1 3.95132V13.0201C1 14.839 2.38593 16.1756 4.20486 16.1756H10.8821C12.604 16.1756 14 14.742 14 13.0201V5.24446L9.7653 0.834229Z"
                    ></path>
                    <path d="M9.54541 0.824432V3.25608C9.54541 4.44307 10.5059 5.40603 11.6929 5.40854C12.7929 5.41104 13.9189 5.41188 13.9949 5.40687"></path>
                    <path
                      d="M8.744 10.368C8.744 10.6614 8.66667 10.9334 8.512 11.184C8.36267 11.4294 8.13867 11.632 7.84 11.792C7.54667 11.9467 7.19733 12.032 6.792 12.048V12.696H6.28V12.04C5.71467 11.992 5.25867 11.824 4.912 11.536C4.56533 11.2427 4.38133 10.8454 4.36 10.344H5.816C5.848 10.664 6.00267 10.8694 6.28 10.96V9.69603C5.864 9.58936 5.53067 9.48536 5.28 9.38403C5.03467 9.2827 4.81867 9.12003 4.632 8.89603C4.44533 8.67203 4.352 8.36536 4.352 7.97603C4.352 7.4907 4.53067 7.10136 4.888 6.80803C5.25067 6.5147 5.71467 6.34936 6.28 6.31203V5.66403H6.792V6.31203C7.352 6.3547 7.79467 6.51736 8.12 6.80003C8.44533 7.0827 8.62667 7.4747 8.664 7.97603H7.2C7.168 7.68803 7.032 7.50136 6.792 7.41603V8.65603C7.23467 8.7787 7.576 8.88803 7.816 8.98403C8.056 9.08003 8.26933 9.24003 8.456 9.46403C8.648 9.6827 8.744 9.98403 8.744 10.368ZM5.8 7.91203C5.8 8.04536 5.84 8.15736 5.92 8.24803C6.00533 8.3387 6.12533 8.4187 6.28 8.48803V7.37603C6.13067 7.4027 6.01333 7.46136 5.928 7.55203C5.84267 7.63736 5.8 7.75736 5.8 7.91203ZM6.792 10.984C6.952 10.9574 7.07733 10.8934 7.168 10.792C7.264 10.6907 7.312 10.5654 7.312 10.416C7.312 10.2774 7.26667 10.1654 7.176 10.08C7.09067 9.98936 6.96267 9.91203 6.792 9.84803V10.984Z"
                      fill="black"
                      strokeWidth={0.5}
                    ></path>
                  </g>
                </svg>
                <span
                  className={`ms-3  text-sm   hover:text-blue-700 font-light `}
                >
                  {lang('lblInvoices')}
                </span>
              </NavLink>
            </li>

            <li className="text-start xs:pl-3 md:pl-6 ">
              <NavLink
                to="cases"
                onClick={() => {
                  handleTabClick('cases');
                  if (window.innerWidth < 768) toggleSideBar();
                }}
                className={({ isActive }) =>
                  `stroke-slate-900 relative flex items-center px-2 py-2 rounded-lg text-black hover:bg-sky-100 dark:bg-sky-300 hover:text-blue-700 ${
                    isActive
                      ? 'bg-sky-100 dark:bg-blue-700 text-blue-700 stroke-blue-600 after-sel'
                      : ''
                  }`
                } // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="cases"
              >
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // stroke="#333333"
                  stroke-width="1.5px"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="hover:stroke-blue-700 stroke-2"
                >
                  <g opacity="0.4">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.7653 0.834211H4.20486C2.4829 0.834211 1 2.22934 1 3.9513V13.02C1 14.839 2.38593 16.1756 4.20486 16.1756H10.8821C12.604 16.1756 14 14.742 14 13.02V5.24445L9.7653 0.834211Z"
                    ></path>
                    <path d="M9.54541 0.824425V3.25607C9.54541 4.44306 10.5059 5.40602 11.6929 5.40853C12.7929 5.41104 13.9189 5.41187 13.9949 5.40686"></path>
                  </g>
                </svg>
                <span
                  className={`ms-3  text-sm  hover:text-blue-700 font-light `}
                >
                  {lang('lblCases')}
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
