import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config/config';
import { lang } from '../config/lang';
import backSvg from '../images/arrow-back-svgrepo-com.svg';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const PreviewMeetings = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const row = location?.state || {};
  const handleEditcase = (data) => {
    navigate('edit', { state: { ...data } });
  };
  const formatDateTime = (dateCreated) => {
    const date = new Date(dateCreated);
    const formattedDate = date.toLocaleDateString('en-GB').replace(/\//g, '-');
    const formattedTime = date.toLocaleTimeString('en-GB', { hour12: false });
    return formattedDate + ' ' + formattedTime;
  };
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <form method="post">
        <div className="add-document-main-div">
          <div className="add-document-inner-div">
            <div className="flex font-medium  justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
              <h2 className=" text-left h-auto  text-xl">
                {lang('lblMeetingDetails')}
              </h2>
              
             {/* <div className="add-doc-btn">
                <div className="px-3">
                  <button
                    className="w-5 "
                    type="button"
                    onClick={() => navigate('/meetings')}
                  >
                    <img src={backSvg} title={lang('lblBtnPrevBack')}></img>
                  </button>
                </div>
              </div>
                */} 
            </div>
            <div className="add-document-heading"></div>
           
            <div className="add-document-inputs">
              <div className="document-name1">
                <label className="text-left text-sm mt-1 font-bold w-32 ">
                  {lang('lblSubject')} <span className="">: </span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {row?.subject || ''}
                </div>
              </div>
              <div className="document-name1 ">
                <label className="text-left text-sm mt-1 font-bold w-32 ">
                  {lang('lblWith')} <span className="">: </span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {' '}
                  {row?.userName || ''}
                </div>
              </div>
           
              <div className="document-name1 ">
                <label
                  htmlFor="type"
                  className="text-left text-sm mt-1 font-bold w-32"
                >
                  {lang('lblStartDate')}
                  <span className="">:</span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {' '}
                  {row.dateStart}
                </div>
              </div>
              <div className="document-name1 ">
                <label className="text-left text-sm mt-1 font-bold w-32 ">
                  {lang('lblStatus')} <span className="">:</span>
                </label>
                <div className="text-sm w-full text-left bg-slate-50 p-1 rounded-lg border pl-2 border-slate-200">
                  {' '}
                  {row.status}
                </div>
              </div>
            </div>
               
          </div>
        </div>
      </form>
    </div>
  );
};

export default PreviewMeetings;
