import React, { useState, useEffect } from 'react';
import Signup from './signup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import pic1 from '../images/ankerhuis1-logo.png';
import pic2 from '../images/cherrywood1-logo.png';
import pic3 from '../images/villa1-logo.png';
import { login } from '../controllers/auth';
import mainImg from '../images/main-login-page.jpg';
import { forgotUserPassword } from '../controllers/auth';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { rememberMeLogin, setCookie } from '../controllers/cookies';
import isTokenExpired from '../utils/isTokenExpired';

const images = [pic1, pic2, pic3];

const Signin = () => {
  const [action, setAction] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [alert1, setAlert1] = useState({ message1: '', severity1: '' });
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [timer, setTimer] = useState(0);

  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  useEffect(() => {
    if (isTokenExpired() === false) {
      navigate('user/dashboard');
    }
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(intervalId);
  }, []);
  const handleEmailChange = (event) => {
    setUserName(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setUserPassword(event.target.value);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!userName)
        return setAlert({
          message: 'Username is required',
          severity: 'error',
        });
      if (!userPassword)
        return setAlert({
          message: 'Password is required',
          severity: 'error',
        });

      if (userName && userPassword) {
        setLoading(true);
        setAlert({
          message: 'Signing in. Please wait ... ',
          severity: 'info',
        });
        try {
          const result = await login(userName, userPassword);
          if (result?.data != null) {
            if (rememberMe) {
              setCookie('username', userName);
              setCookie('password', userPassword);
            }
            navigate('/user/dashboard');
          } else {
            throw { message: 'Username or password is invalid' };
          }
        } catch (err) {
          console.log(err);
          if (err.status === 400) {
            setAlert({
              message: 'Username or password is invalid',
              severity: 'error',
            });
          } else {
            setAlert({
              message: err.message || 'Username or password is invalid',
              severity: 'error',
            });
          }
        } finally {
          setLoading(false);
        }
      } else {
        setAlert({
          message: 'Please fill all required fields',
          severity: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      if (err.status === 400) {
        setAlert({
          message: 'User not found',
          severity: 'error',
        });
        setError('User not found');
      } else {
        setAlert({
          message: err.message || 'User not found',
          severity: 'error',
        });
      }
    }
  };
  const handleSignUpClick = () => {
    navigate('/register'); // Navigate to the registration route
  };

  // Effect to handle the countdown logic
  useEffect(() => {
    let interval = null;
    if (!isResendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isResendEnabled, timer]);

  const handleCnfrmPass = async (event) => {
    event.preventDefault();

    if (!forgotEmail) {
      setAlert1({
        message1: 'Email field is required',
        severity1: 'error',
      });
      return;
    }
    try {
      setAlert1({
        message1: 'Please wait while we send you a reset link',
        severity1: 'info',
      });
      const response = await forgotUserPassword({ email: forgotEmail });
      if (response?.data?.status == 'success') {
        setAlert1({
          message1: response.data.message,
          severity1: 'success',
        });

        setTimer(30);
        setIsResendEnabled(false);
      }
      setAlert1({
        message1:
          response.data.message ||
          'Email not send, something went wrong. please try again later',
        severity1: 'error',
      });
      console.log('End response: ', response);
    } catch (err) {
      setAlert1({
        message1: err.message || 'Error occurred while sending reset link',
        severity1: 'error',
      });
      console.log(err);
    }
    // navigate('/ConfirmPassword'); // Navigate to the registration route
  };

  // Effect to handle the countdown logic
  useEffect(() => {
    let interval = null;
    if (!isResendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isResendEnabled, timer]);

  const handlegoBack = () => {
    setShowSignup(false);
  };
  return (
    <div id="container">
      <div className=" w-full flex h-full md:flex-row xs:flex-col">
        <div className="xs:w-full md:w-1/2 h-full flex justify-center items-center overflow-hidden relative">
          <img
            // src={images[currentIndex]}
            // alt={`Slide ${currentIndex + 1}`}
            className="w-full h-full object-cover"
            src={mainImg}
          />
          {/* <div className="absolute top-16 text-white text-4xl">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
              praesentium?
            </p>
          </div> */}
        </div>
        <div className="xs:w-full md:w-1/2">
          {
            // === not true
            <div id="login">
              <div className="md:text-3xl xs:text-xl md:font-bold xs:font-semibold ">
                <h1>Client Portal</h1>
              </div>
              <div className="flex gap-0 m-0 p-0 md:w-96 xs:w-60 bg-contain">
                <img src={pic2}></img>
                <img src={pic1} className="my-0" />
                <img src={pic3}></img>
              </div>
              {action === false ? (
                <>
                  <p className="portal"> Forgot Password</p>

                  {alert1.message1 && (
                    <Stack sx={{ width: '50%' }} spacing={2}>
                      <Alert severity={alert1.severity1}>
                        {alert1.message1}
                      </Alert>
                    </Stack>
                  )}
                  <input
                    type="text"
                    name="forgotPassEmail"
                    placeholder="Enter your email"
                    className="signinjs"
                    value={forgotEmail}
                    onChange={(e) => setForgotEmail(e.target.value)}
                  ></input>
                </>
              ) : (
                <div></div>
              )}
              {action === true ? (
                <>
                  {alert.message && (
                    <Stack sx={{ width: '50%' }} spacing={2}>
                      <Alert severity={alert.severity}>{alert.message}</Alert>
                    </Stack>
                  )}
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={userName}
                    onChange={handleEmailChange}
                    placeholder="Enter your user name"
                    className="signinjs"
                  ></input>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={userPassword}
                    required
                    autoComplete="current-password"
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                    className="signinjs"
                  ></input>
                </>
              ) : (
                <div></div>
              )}
              <div className="CheckBox">
                <div className="check-box">
                  {action === false ? (
                    <div></div>
                  ) : (
                    <>
                      <input
                        id="checkbox1"
                        type="checkbox"
                        className="bg-white"
                        onChange={(e) => setRememberMe(e.target.checked)}
                        checked={rememberMe}
                      ></input>
                      <label for="checkbox1" className="">
                        Remember me
                      </label>
                    </>
                  )}
                </div>
                {action === false ? (
                  <div></div>
                ) : (
                  <>
                    <a
                      className="cursor-pointer"
                      // onClick={() => navigate('/forgotPassword')}
                      onClick={() => setAction(false)}
                    >
                      Forgot Password?
                    </a>
                  </>
                )}
              </div>
              {action === true ? (
                <div className="btn1">
                  <button
                    onClick={handleLogin}
                    disabled={loading}
                    className="signin-up-btn"
                  >
                    {loading ? 'loading...' : 'Sign In'}
                  </button>
                </div>
              ) : (
                <div className="btn1">
                  {isResendEnabled ? (
                    <button
                      onClick={handleCnfrmPass}
                      disabled={loading}
                      className="signin-up-btn"
                    >
                      Send Email
                    </button>
                  ) : (
                    `Resend in ${timer}s`
                  )}
                </div>
              )}

              {action === false ? (
                <div>
                  {/* <div className="footer">
                  <p>Did not get Email? </p>
                  <a href="#">Resend</a>
                  </div> */}
                  <div>
                    <a
                      className="footer"
                      href="#"
                      onClick={() => {
                        setAction(true);
                      }}
                    >
                      Back To Login
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <div className="footer">
                    {/* <p>If you don't have an account you can</p>
                    <a onClick={handleSignUpClick}>Register here!</a> */}
                  </div>
                </>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};
document.getElementsByClassName('signinjs');
export default Signin;
