export const nl = {
  //Documents
  lblDocuments: 'Documenten', //repeated
  lblUpload: 'Uploaden',
  lblDocumentName: 'Documentnaam',
  lblFileName: 'Bestandsnaam',
  lblType: 'Type', //repeated
  lblDateCreated: 'Aanmaakdatum', //repeated
  lblAction: 'Actie', //repeated
  lblExport: 'Exporteren', //repeated
  lblFooterRowPage: 'Rijen per pagina', //repeated
  lblEditDocument: 'Bewerk Document',
  lblDownloadDocument: 'Download Document',
  lblViewDocument: 'Bekijk Document',
  //Add-Doc
  lblAddDocAlertSucc: 'Document succesvol aangemaakt', //repeated
  lblAddDocAlertError: 'Fout bij het aanmaken van document', //repeated
  lblAddDocAlertReq: 'Vul alle verplichte velden in', //repeated
  lblUploadDoc: 'Document uploaden',
  lblUploadFile: 'Bestand uploaden',
  lblNote: 'Opmerking', //repeated
  lblBtnCancel: 'Annuleren', //repeated
  lblBtnSave: 'Opslaan', //repeated
  lblBtnSaving: 'Opslaan...', //repeated
  lblMaxFileSize: 'Maximale uploadbestandsgrootte: 15 MB.',
  //previewDoc
  lblPrevDoc: 'Document',
  lblBtnEdit: 'Bewerken',
  //EditDoc
  lblEditDoc: 'Document bewerken',
  lblViewDoc: 'Bekijken',
  lblRemoveDoc: 'Verwijderen',
  lblUpdate: 'Bijwerken...',
  //Meetings
  lblMeetings: 'Vergaderingen',
  lblMeetingDetails: 'Vergaderdetails',
  lblSubject: 'Onderwerp', //repeated
  lblStatus: 'Status', //repeated
  lblStartDate: 'Startdatum',
  lblWith: 'Met',
  lblViewMeet: 'Bekijk Vergadering',
  //PrevMeeting
  lblBtnPrevBack: 'Terug',
  //dashboardMeetings
  lblSchedule: 'Schema van vandaag',
  lblTodays: 'Geen records',
  lblDuration: 'Duur',
  //Invoices
  lblInvoices: 'Facturen',
  lblTitle: 'Titel',
  lblDueDate: 'Vervaldatum',
  lblGrandTotal: 'Totaalbedrag',
  lblInvoiceDate: 'Factuurdatum',
  lblViewInvoice: 'Bekijk Factuur',
  lblDownloadInvs: 'Download Factuur',
  //Cases
  lblCases: 'MijnVragen',
  lblCaseNumb: 'ID-nummer',
  lblPriority: 'Prioriteit',
  lblBtnAddCase: 'Toevoegen',
  //AddCase
  lblAddCase: 'Stel een vraag',
  lblQuestion: 'Ik heb een vraag over mijn:',
  lblDescription: 'Beschrijving',
  lblAttachment: 'Bijlage toevoegen',
  lblAttachmentPara: 'Klik om te uploaden',
  lblAttachmentPara2: 'of slepen en neerzetten',
  lblSuccessAddCase: 'Besparing ...',
  lblAlertAddCase: 'Vraag succesvol verzonden!',
  lblErrorAddCase: 'Het indienen van de vraag is mislukt!',
  lblViewCase: 'Bekijk MijnVragen',
  lblBtnSendQuestion: 'Versturen',
  lblQuestionCategory: 'Categorie',
  //editcase
  lblinprocessEditCase: 'Vraag wordt bijgewerkt, een ogenblik geduld...',
  lblsuccessEditCase: 'Vraag is succesvol bijgewerkt',
  lblFailEditCase: 'Het updaten van de vraag is mislukt',
  lblEditCase: 'Bewerk MijnVragen',
  lblQuestionEditCase: 'Ik heb een vraag over',
  //prevCase
  lblPrevCase: 'Vraag',
  //Side-Bar
  lblDashboard: 'Dashboard',
  lblAlert: 'Weet u zeker dat u wilt verwijderen',
  //header
  lblGreet: 'Hallo, ',
  lblViewProf: 'Profiel bekijken',
  lblLogOut: 'Uitloggen',
  //userProfile
  lblProfileHead: 'Profielinstelling',
  lblFirstName: 'Voornaam:',
  lblLastName: 'Achternaam:',
  lblName : 'Naam:',
  lblEmail: 'E-mail',
  lblPhnNumb: 'Telefoonnummer:',
  lblAddress: 'Adres:',
  lblStreet: 'Straat',
  lblCity: 'Stad',
  lblState: 'Staat/Regio:',
  lblPostCode: 'Postcode:',
  lblCountry: 'Land',
  //dutch
  lblUploadReferralMainB: 'Upload Verwijsbrief Kliniek',
  lblUploadReferralMainA: 'Upload Verwijsbrief Nazorg',
  lblUploadReferralMainDownloadB: 'Bekijk doorverwijzing brief Kliniek',
  lblUploadReferralMainDownloadA: 'Bekijk doorverwijzing brief Nazorg',
  //notification
  lblNotification: 'Melding(en)',
  lblNotifiDate: 'Datum',
  //new
  lblSummary: 'Zaakoverzicht',
  //type and priority dropdowns
  lblPriorityHigh: 'Hoog',
  lblPriorityMed: 'Gemiddeld',
  lblPriorityLow: 'Laag',
  lblAddmissionProgress: 'Toelatingsvoortgang',
  lblTreatment: 'Behandeling',
  lblInvoices: 'Factuur',
  lblAfterCare: 'Nazorg',
  lblProfileInfo: 'Profielinformatie',
  lblOther: 'Overig',
  lblTotalCase: 'Totaal aantal zaken',
  //doc types
  lblPid: 'PID',
  lblTreatmentPlan: 'Behandelplan',
  lblIntake: 'Intakeverslag',
  lblMate: 'MATE',
  lblDefense: 'Verweerbrief',
  lblAppForInsurance: 'Aanvraag voor verzekering',
  lblDischarge: 'Ontslagrapport',
  lblReferralLetterClinic: 'Verwijsbrief (Kliniek)',
  lblReferralLetterAftercare: 'Verwijsbrief (Nazorg)',
  lblConfirmationLetterAfterCare: 'Bevestigingsbrief Nazorg',
  lblTreatmentHistory: 'Behandelgeschiedenis',
  lblInsurerDocument: 'Verzekeringsdocument',
  lblProofOfPayment: 'Betalingsbewijs',
  lblAccountabilityDocument: 'Verantwoordingsdocument',
  lblMotivationLetter: 'Motivatiebrief',
  lblFlightDetails: 'Vluchtgegevens',
  lblOtherDoc: 'Overig',  
  //header
  lblnoNotification: 'Geen nieuwe meldingen',
  lblNotificationbtn: 'Bekijk alle meldingen',
  //threads
  lblThreads: 'Zaakinformatie/Discussies',
  lblNoMsg: 'Geen berichten meer',
  lblLoading: 'Laden...',
  lblQuickLinks : 'Snelle links',
};
