import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './components/login';
import Register from './components/registration';
import UserProfile from './components/MemberProfile';
import Ticketing from './pages/Members/ticketing';
import ViewMeetings from './components/ViewMeetings';
import CreateMeeting from './components/CreateMeetings';
import Resolveickets from './components/Resolveickets';
import AdminDashboard from './pages/Admin/AdminDashboard';
import MemberDashboard from './pages/Members/MemberDashboard';
import AdminUserDashboard from './pages/AdminUser/AdminUserDashboard';
import ViewMembers from './components/ViewMembers';
import Logout from './components/logout';
import ViewUsers from './components/viewUsers';
//import ReqAuthentication from './components/ReqAuthentication';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedAdminDashboard from './components/ProtectedAdminDashboard';
import EmailConfirmation from './components/EmailConfirmation';
import MemberHomePage from './pages/Members/MemberHomePage';
import UploadDocuments from './pages/Members/UploadDocuments';
import Invoices from './pages/Members/Invoices';
import Signin from './components/signin';
import Signup from './components/signup';
import UserProfile1 from './components/userprofile';
import ForgotPassword from './components/ForgotPassword';
import AddCase from './pages/Members/AddCase';
import MeetingCard from './components/MeetingCard';
import AddDocument from './components/AddDocument';
import Cases from './components/Cases';
import AdminSidebarIcons from './pages/Admin/AdminSidebarIcons';
import EditDocument from './components/EditDocument';
import Preview from './components/Preview';
import ChangePass from './components/ChangePass';
import LoginPass from './components/LoginPass';
import EditCase from './components/EditCase';
import PreviewCase from './components/PreviewCase';
import Meeting from './components/Meeting';
import PreviewMeetings from './components/PreviewMeetings';
import PreviewInvoices from './components/PreviewInvoices';
import Notification from './components/notification';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/Logout" element={<Logout />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/user/resetPassword/:id" element={<LoginPass />} />
          <Route path="/emailConfirmation" element={<EmailConfirmation />} />

          {/* <ProtectedRoute path="/AdminDashboard" component={<AdminDashboard />} /> */}

          {/* Admin Routes */}

          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          >
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <UserProfile1 />
                </ProtectedRoute>
              }
            />
            <Route
              path="notification"
              element={
                <ProtectedRoute>
                  <Notification />
                </ProtectedRoute>
              }
            />
            <Route
              path="createMeet"
              element={
                <ProtectedRoute>
                  <CreateMeeting />
                </ProtectedRoute>
              }
            />
            <Route
              path="resolveTickets"
              element={
                <ProtectedRoute>
                  <Resolveickets />
                </ProtectedRoute>
              }
            />
            <Route
              path="viewMembers"
              element={
                <ProtectedRoute>
                  <ViewMembers />
                </ProtectedRoute>
              }
            />
            <Route
              path="viewUsers"
              element={
                <ProtectedRoute>
                  <ViewUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="uploadDocuments"
              element={
                <ProtectedRoute>
                  <UploadDocuments />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <MemberHomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="documents"
              element={
                <ProtectedRoute>
                  <UploadDocuments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/dashboard/documents"
              element={
                <ProtectedRoute>
                  <UploadDocuments />
                </ProtectedRoute>
              }
            />
            <Route
              path="invoices"
              element={
                <ProtectedRoute>
                  <Invoices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/dashboard/invoices"
              element={
                <ProtectedRoute>
                  <Invoices />
                </ProtectedRoute>
              }
            />
            <Route
              path="invoices/preview"
              element={
                <ProtectedRoute>
                  <PreviewInvoices />
                </ProtectedRoute>
              }
            />
            <Route
              path="cases/addCase"
              element={
                <ProtectedRoute>
                  <AddCase />
                </ProtectedRoute>
              }
            />
            <Route
              path="cases/edit/:id"
              element={
                <ProtectedRoute>
                  <EditCase />
                </ProtectedRoute>
              }
            />
            <Route
              path="cases/preview/edit"
              element={
                <ProtectedRoute>
                  <EditCase />
                </ProtectedRoute>
              }
            />
            <Route
              path="cases/detail/:id"
              element={
                <ProtectedRoute>
                  <PreviewCase />
                </ProtectedRoute>
              }
            />
            <Route
              path="notification/cases/detail/:id"
              element={
                <ProtectedRoute>
                  <PreviewCase />
                </ProtectedRoute>
              }
            />

            <Route
              path="meetings/detail/:id"
              element={
                <ProtectedRoute>
                  <PreviewMeetings />
                </ProtectedRoute>
              }
            />
            <Route
              path="viewMeetings"
              element={
                <ProtectedRoute>
                  <ViewMeetings />
                </ProtectedRoute>
              }
            />
            <Route
              path="meetings-card"
              element={
                <ProtectedRoute>
                  <MeetingCard />
                </ProtectedRoute>
              }
            />
            <Route
              path="meetings"
              element={
                <ProtectedRoute>
                  <Meeting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/dashboard/meetings"
              element={
                <ProtectedRoute>
                  <Meeting />
                </ProtectedRoute>
              }
            />
            <Route path="documents/add" element={<AddDocument />} />
            <Route
              path="cases"
              element={
                <ProtectedRoute>
                  <Cases />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/dashboard/cases"
              element={
                <ProtectedRoute>
                  <Cases />
                </ProtectedRoute>
              }
            />
            <Route
              path="AdminIcons"
              element={
                <ProtectedRoute>
                  <AdminSidebarIcons />
                </ProtectedRoute>
              }
            />
            <Route
              path="documents/edit/:id"
              element={
                <ProtectedRoute>
                  <EditDocument />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/add"
              element={
                <ProtectedRoute>
                  <AddDocument />
                </ProtectedRoute>
              }
            />
            <Route
              path="documents/preview/edit"
              element={
                <ProtectedRoute>
                  <EditDocument />
                </ProtectedRoute>
              }
            />
            <Route
              path="documents/detail/:id"
              element={
                <ProtectedRoute>
                  <Preview />
                </ProtectedRoute>
              }
            />
            <Route
              path="ChangePassword"
              element={
                <ProtectedRoute>
                  <ChangePass />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* Admin  Routes */}

          {/* Admin User Routes */}

          <Route
            path="/AdminUserDashboard"
            element={
              <ProtectedRoute>
                <AdminUserDashboard />
              </ProtectedRoute>
            }
          >
            {/* <Route path="userProfile" element={<UserProfile />} />
            <Route path="ViewMeetings" element={<ViewMeetings />} /> */}
          </Route>
          {/* Admin User Routes */}

          {/* Member Routes */}

          <Route
            path="/MemberDashboard"
            element={
              <ProtectedRoute>
                <MemberDashboard />
              </ProtectedRoute>
            }
          ></Route>
          {/* Member Routes */}

          {/* Add other routes here */}
          {/* <Route path="/" element={<h1>Welcome! Please go to /login or /register</h1>} /> */}

          {/* Routes from Hazik Qureshi */}

          {/* <Route path="/UserProfile" element={<UserProfile1 />} /> */}

          {/* Routes from Hazik Qureshi */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
