import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SuitCRMLOGO from '../../images/suitecrm-logo.png';
import HomeIcon from '@mui/icons-material/Home';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { initFlowbite } from 'flowbite';
const MemberSidebar = () => {
  const location = useLocation();
  const handleTabClick = (tabName) => {};
  useEffect(() => {
    initFlowbite();
  }, []);
  return (
    <div className="h-full">
      <div
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-44 h-screen overflow-y-auto transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
        // ref={sidebarRef}
      >
        <div className="h-100 px-3 py-4 bg-white h-full">
          <Link
            to="/"
            className="flex items-center justify-center ps-2.5 w-full overflow-x-hidden relative"
          >
            <img src={SuitCRMLOGO} className={`flex-shrink-0 w-full h-full`} />{' '}
            <div className="absolute bottom-0 w-full left-0 right-0 border-b border-white"></div>
          </Link>
          <ul className="space-y-2 font-medium text-black mt-3">
            <li className="text-start">
              <NavLink
                to="/MemberDashboard/memberHomepage"
                className={({ isActive }) =>
                  `relative flex items-center p-1 rounded-lg hover:text-sky-700 font-light ${
                    isActive ? 'bg-sky-100 dark:bg-sky-300 text-sky-700' : ''
                  }`
                }
                onClick={() => handleTabClick('memberProfile')}
                // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="memberProfile"
              >
                <HomeIcon className={`flex-shrink-0 w-7 h-7`} />
                <span className={`ms-3`}>Dashboard</span>
              </NavLink>
            </li>
            <hr className="my-5" />
            <li className="text-start ">
              <NavLink
                to="/MemberDashboard/addTicket"
                onClick={() => handleTabClick('addTicket')}
                className={({ isActive }) =>
                  `relative flex items-center p-1 rounded-lg hover:text-sky-700 font-light ${
                    isActive ? 'bg-sky-100 dark:bg-sky-300 text-sky-700' : ''
                  }`
                }
                data-tab="addTicket"
              >
                <SummarizeIcon className={`flex-shrink-0 w-7 h-7`} />
                <span className={`ms-3`}>Myquestions</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/MemberDashboard/viewMeetings"
                onClick={() => handleTabClick('viewMeetings')}
                className={({ isActive }) =>
                  `relative flex items-center p-1 rounded-lg hover:text-sky-700 font-light ${
                    isActive ? 'bg-sky-100 dark:bg-sky-300 text-sky-700' : ''
                  }`
                } // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="viewMeetings"
              >
                <AssignmentIcon className={`flex-shrink-0 w-7 h-7`} />
                <span className={`ms-3`}>Meetings</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/MemberDashboard/invoices"
                onClick={() => handleTabClick('invoices')}
                className={({ isActive }) =>
                  `relative flex items-center p-1 rounded-lg hover:text-sky-700 font-light ${
                    isActive ? 'bg-sky-100 dark:bg-sky-300 text-sky-700' : ''
                  }`
                } // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="invoices"
              >
                <ReceiptIcon className={`flex-shrink-0 w-7 h-7`} />
                <span className={`ms-3`}>Invoices</span>
              </NavLink>
            </li>
            <li className="text-start">
              <NavLink
                to="/MemberDashboard/documents"
                onClick={() => handleTabClick('documents')}
                className={({ isActive }) =>
                  `relative flex items-center p-1 rounded-lg hover:text-sky-700 font-light ${
                    isActive ? 'bg-sky-100 dark:bg-sky-300 text-sky-700' : ''
                  }`
                } // className={`relative flex items-center p-3 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                data-tab="documents"
              >
                <UploadFileIcon className={`flex-shrink-0 w-7 h-7  `} />
                <span className={`ms-3`}>Documents</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MemberSidebar;
